export const notificationsData = {
  otpNotification: {
    name: "Login/Signup OTP",
    fieldSmsEng: "otpNotificationEn",
    fieldSmsAr: "otpNotificationAr",
    activeSms: "activeOtpNotificationSms",
  },
  scheduleNotification: {
    name: "Schedule Appointment",
    fieldSmsEng: "scheduleNotifySmsEng",
    fieldSmsAr: "scheduleNotifySmsAr",
    activeSms: "activeScheduleNotifySms",
    fieldEmailEng: "scheduleNotifyEmailEng",
    fieldEmailAr: "scheduleNotifyEmailAr",
  },
  scheduleNotificationWithCareemPromo: {
    name: "Schedule Appointment (With Careem Promo)",
    fieldSmsEng: "scheduleNotifyCareemPromoSmsEng",
    fieldSmsAr: "scheduleNotifyCareemPromoSmsAr",
    activeSms: "activeScheduleNotifyCareemPromoSms",
  },
  rescheduleNotification: {
    name: "Reschedule Appointment",
    fieldSmsEng: "rescheduleNotifySmsEng",
    fieldSmsAr: "rescheduleNotifySmsAr",
    activeSms: "activeRescheduleNotifySms",
    fieldEmailEng: "rescheduleNotifyEmailEng",
    fieldEmailAr: "rescheduleNotifyEmailAr",
  },
  cancelNotification: {
    name: "Cancel Appointment",
    fieldSmsEng: "cancelNotifySmsEng",
    fieldSmsAr: "cancelNotifySmsAr",
    activeSms: "activeCancelNotifySms",
    fieldEmailEng: "cancelNotifyEmailEng",
    fieldEmailAr: "cancelNotifyEmailAr",
  },
  fastCollect: {
    name: "Appointment - Fast Collect",
    fieldSmsEng: "fastCollectNotifySmsEng",
    fieldSmsAr: "fastCollectNotifySmsAr",
    activeSms: "activeFastCollectSms",
  },
  otherCarNotification: {
    name: "Appointment from Other Car",
    fieldSmsEng: "otherCarNotifySmsEng",
    fieldSmsAr: "otherCarNotifySmsAr",
    activeSms: "activeOtherCarNotifySms",
    fieldEmailEng: "otherCarNotifyEmailEng",
    fieldEmailAr: "otherCarNotifyEmailAr",
  },
  estimationRequest: {
    name: "Estimation Request Creation",
    fieldSmsEng: "estimationRequestSmsEng",
    fieldSmsAr: "estimationRequestSmsAr",
    activeSms: "activeEstimationRequestSms",
  },
  closeEstimationRequest: {
    name: "Close Estimation Request",
    fieldSmsEng: "closeEstimationRequestSmsEng",
    fieldSmsAr: "closeEstimationRequestSmsAr",
    activeSms: "activeCloseEstimationRequestSms",
  },
  estimationAppointment: {
    name: "Estimation Schedule Appointment Cancel",
    fieldSmsEng: "estimationAppointmentNotifySmsEng",
    fieldSmsAr: "estimationAppointmentNotifySmsAr",
    fieldEmailEng: "estimationAppointmentNotifyEmailEng",
    fieldEmailAr: "estimationAppointmentNotifyEmailAr",
    activeSms: "activeEstimationAppointmentNotifySms",
  },
  estimationApproval: {
    name: "Estimation Approval",
    fieldSmsEng: "estimationApprovalNotifySmsEng",
    fieldSmsAr: "estimationApprovalNotifySmsAr",
    fieldEmailEng: "estimationApprovalNotifyEmailEng",
    fieldEmailAr: "estimationApprovalNotifyEmailAr",
    activeSms: "activeEstimationApprovalSms",
  },
  estimationComment: {
    name: "Estimation Comment",
    fieldSmsEng: "estimationCommentNotifySmsEng",
    fieldSmsAr: "estimationCommentNotifySmsAr",
    fieldEmailEng: "estimationCommentNotifyEmailEng",
    fieldEmailAr: "estimationCommentNotifyEmailAr",
    activeSms: "activeEstimationCommentSms",
  },
  express: {
    name: "Express",
    fieldSmsEng: "expressNotifySmsEng",
    fieldSmsAr: "expressNotifySmsAr",
    fieldEmailEng: "expressNotifyEmailEng",
    fieldEmailAr: "expressNotifyEmailAr",
    activeSms: "activeExpressNotifySms",
  },
  paymentAddon: {
    name: "Payment Confirmation for Appointments with Add-ons ",
    fieldSmsEng: "paymentAddonNotifySmsEng",
    fieldSmsAr: "paymentAddonNotifySmsAr",
    fieldEmailEng: "paymentAddonNotifyEmailEng",
    fieldEmailAr: "paymentAddonNotifyEmailAr",
    activeSms: "activePaymentAddonNotifySms",
  },
  paymentOffer: {
    name: "Payment Confirmation for Appointment with Offer",
    fieldSmsEng: "paymentOfferNotifySmsEng",
    fieldSmsAr: "paymentOfferNotifySmsAr",
    fieldEmailEng: "paymentOfferNotifyEmailEng",
    fieldEmailAr: "paymentOfferNotifyEmailAr",
    activeSms: "activePaymentOfferNotifySms",
  },
  paymentUpsell: {
    name: "Payment Confirmation for E-Shop & Upsell",
    fieldSmsEng: "paymentUpsellNotifySmsEng",
    fieldSmsAr: "paymentUpsellNotifySmsAr",
    fieldEmailEng: "paymentUpsellNotifyEmailEng",
    fieldEmailAr: "paymentUpsellNotifyEmailAr",
    activeSms: "activePaymentUpsellNotifySms",
  },
  expressCustomerNotification: {
    name: "Express Customer Timed",
    fieldSmsEng: "expresscustomerNotificationSmsEn",
    fieldSmsAr: "expresscustomerNotificationSmsAr",
    activeSms: "activeExpressCustomerNotificationSms",
  },
  expressComment: {
    name: "Express Comment",
    fieldSmsEng: "expressCommentEn",
    fieldSmsAr: "expressCommentAr",
    activeSms: "activeExpressCommentSms",
  },
  expressConfiramtionNotification: {
    name: "Express Confirmation",
    fieldSmsEng: "expressConfiramtionNotificationSmsEn",
    fieldSmsAr: "expressConfiramtionNotificationSmsAr",
    activeSms: "activeExpressConfirmationNotificationSms",
  },
  finalInvoice: {
    name: "Final Invoice",
    fieldSmsEng: "finalInvoiceSmsEn",
    fieldSmsAr: "finalInvoiceSmsAr",
    activeSms: "activeFinalInvoiceSms",
  },
  refundContent: {
    name: "Refund",
    fieldSmsEng: "refundContentSmsEn",
    fieldSmsAr: "refundContentSmsAr",
    activeSms: "activeRefundContentSms",
  },
  recommendedOfferContent: {
    name: "Tailor-Made Offer",
    fieldSmsEng: "recommendedOfferSmsEn",
    fieldSmsAr: "recommendedOfferSmsAr",
    activeSms: "activeRecommendedOfferSms",
  },
  fastCollectSatisfiedSms: {
    name: "Fast Collect Satisfied Sms",
    fieldSmsEng: "fastCollectSatisfiedSmsEn",
    fieldSmsAr: "fastCollectSatisfiedSmsAr",
    activeSms: "activeFastCollectSatisfiedSms",
  },
  fastCollectNotSatisfiedSms: {
    name: "Fast Collect Not Satisfied Sms",
    fieldSmsEng: "fastCollectNotSatisfiedSmsEn",
    fieldSmsAr: "fastCollectNotSatisfiedSmsAr",
    activeSms: "activeFastCollectNotSatisfiedSms",
  },
  fastCollectRepairCompletedSms: {
    name: "Fast Collect Repair Completed SMS",
    fieldSmsEng: "fastCollectRepairCompletedSmsEn",
    fieldSmsAr: "fastCollectRepairCompletedSmsAr",
    activeSms: "activeFastCollectRepairCompletedSms",
  },
  quoteBookAndPay: {
    name: "Quote Book & Pay Approved Request SMS",
    fieldSmsEng: "quoteBookAndPaySmsEn",
    fieldSmsAr: "quoteBookAndPaySmsAr",
    activeSms: "activeQuoteBookAndPaySms",
  },
  quoteBookAndPayUserRequest: {
    name: "Quote Book & Pay Customer Request SMS",
    fieldSmsEng: "quoteBookAndPayUserRequestSmsEn",
    fieldSmsAr: "quoteBookAndPayUserRequestSmsAr",
    activeSms: "activeQuoteBookAndPayUserRequestSms",
  },
  subscriptionBuy: {
    name: "Subscription Buy SMS (Customer)",
    fieldSmsEng: "subscriptionBuySmsEn",
    fieldSmsAr: "subscriptionBuySmsAr",
    activeSms: "activeSubscriptionBuySms",
  },
  docsVerifiedForCND: {
    name: "C&D - Documents Verified SMS",
    fieldSmsEng: "docsVerifiedForCNDSmsEn",
    fieldSmsAr: "docsVerifiedForCNDSmsAr",
    activeSms: "activeDocsVerifiedForCustomerSms",
  },
  carReceivedAtCenterForCND: {
    name: "C&D - Car Received at Center Confirmation SMS",
    fieldSmsEng: "carReceivedAtCenterForCNDSmsEn",
    fieldSmsAr: "carReceivedAtCenterForCNDSmsAr",
    activeSms: "activeCarReceivedAtCenterForCndSms",
  },
  scheduleDeliveryForCND: {
    name: "C&D - Schedule Delivery of Vehicle SMS",
    fieldSmsEng: "scheduleDeliveryForCNDSmsEn",
    fieldSmsAr: "scheduleDeliveryForCNDSmsAr",
    activeSms: "activeScheduleDeliveryForCndSms",
  },
  serviceCompleteForCND: {
    name: "C&D - Service Completed and Car Delivered SMS",
    fieldSmsEng: "serviceCompleteForCNDSmsEn",
    fieldSmsAr: "serviceCompleteForCNDSmsAr",
    activeSms: "activeServiceCompletedForCndSms",
  },
  qmsUnservedCustomers: {
    name: "QMS Unserved Customers Reminder",
    fieldSmsEng: "qmsUnservedCustomerSmsEn",
    fieldSmsAr: "qmsUnservedCustomerSmsAr",
    activeSms: "activeQmsUnservedCustomerSms",
  },
  careemCodeGenerationSms: {
    name: "Careem Promo Code Generation User SMS",
    fieldSmsEng: "careemCodeUserSmsEn",
    fieldSmsAr: "careemCodeUserSmsAr",
    activeSms: "activeCareemCodeUserSms",
  },
  collectionAppointmentSms: {
    name: "Collection Appointment User SMS",
    fieldSmsEng: "collectionAppointmentSmsEn",
    fieldSmsAr: "collectionAppointmentSmsAr",
    activeSms: "activeCollectionAppointmentSms",
  },
  // for email page
  serviceAdvisorNotifications: {
    name: "Service Advisor Content",
    subjectEn: "serviceAdvisorSubjectEn",
    fieldEmailEng: "serviceAdvisorNotifyEmailEng",
    fieldEmailAr: "serviceAdvisorNotifyEmailAr",
    emailsTo: "serviceAdvisorToEmails",
    emailsCC: "serviceAdvisorCCEmails",
  },
  FastCollectSatisfiedCustomerServiceAdvisor: {
    name: "Service Advisor Fast Collect Satisfied Customer",
    subjectEn: "fastCollectSatisfiedSubjectEn",
    fieldEmailEng: "fastCollectSatisfiedCustomerEn",
    fieldEmailAr: "fastCollectSatisfiedCustomerAr",
    emailsTo: "fastCollectServiceAdvisorSatisfiedToEmails",
    emailsCC: "fastCollectServiceAdvisorSatisfiedCCEmails",
  },
  FastCollectNotSatisfiedCustomerServiceAdvisor: {
    name: "Service Advisor Fast Collect Not Satisfied Customer",
    subjectEn: "fastCollectNotSatisfiedSubjectEn",
    fieldEmailEng: "fastCollectNotSatisfiedCustomerEn",
    fieldEmailAr: "fastCollectNotSatisfiedCustomerAr",
    emailsTo: "fastCollectServiceAdvisorNotSatisfiedToEmails",
    emailsCC: "fastCollectServiceAdvisorNotSatisfiedCCEmails",
  },
  forgotPasswordEmail: {
    name: "Forgot Password Email",
    subjectEn: "forgotPasswordEmailSubjectEn",
    fieldEmailEng: "forgotPasswordEmailFieldEn",
    fieldEmailAr: "forgotPasswordEmailFieldAr",
  },
  setPasswordScreenForAdmin: {
    name: "Set Password For Admin",
    subjectEn: "setPasswordScreenForAdminSubjectEn",
    fieldEmailEng: "setPasswordScreenForAdminEmailFieldEn",
    fieldEmailAr: "setPasswordScreenForAdminEmailFieldAr",
  },
  orderRefundRequestEmail: {
    name: "Order Refund Request Email",
    subjectEn: "orderRefundRequestEmailSubjectEn",
    subjectAr: "orderRefundRequestEmailSubjectAr",
    fieldEmailEng: "orderRefundRequestEmailFieldEn",
    fieldEmailAr: "orderRefundRequestEmailFieldAr",
    emailsTo: "orderRefundRequestToEmail",
    emailsCC: "orderRefundRequestCCEmails",
  },
  orderRefundEmail: {
    name: "Order Refund Email",
    subjectEn: "orderRefundEmailSubjectEn",
    fieldEmailEng: "orderRefundEmailFieldEn",
    fieldEmailAr: "orderRefundEmailFieldAr",
    emailsTo: "orderRefundToEmail",
    emailsCC: "orderRefundCCEmails",
  },
  eInvoiceErrorNotification: {
    name: "E-invoice Error Notification",
    subjectEn: "eInvoiceErrorNotificationSubjectEn",
    fieldEmailEng: "eInvoiceErrorNotificationFieldEn",
    fieldEmailAr: "eInvoiceErrorNotificationFieldAr",
    emailsTo: "eInvoiceErrorNotificationToEmails",
    emailsCC: "eInvoiceErrorNotificationToCc",
  },
  vehicleCheckedInNotification: {
    name: "Vehicle Checked In Notification",
    subjectEn: "vehicleCheckedInNotificationSubjectEn",
    subjectAr: "vehicleCheckedInNotificationSubjectAr",
    fieldEmailEng: "vehicleCheckedInNotificationFieldEn",
    fieldEmailAr: "vehicleCheckedInNotificationFieldAr",
    emailsTo: "vehicleCheckedInNotificationToEmails",
    emailsCC: "vehicleCheckedInNotificationToCc",
  },
  fastDropEmail: {
    name: "Fast Drop Email",
    subjectEn: "fastDropEmailSubjectEn",
    fieldEmailEng: "fastDropEmailFieldEn",
    fieldEmailAr: "fastDropEmailFieldAr",
    emailsTo: "fastDropToEmail",
    emailsCC: "fastDropCCEmails",
  },
  collectionAppointmentEmail: {
    name: "Collection Appointment Email",
    subjectEn: "collectionAppointmentSubjectEn",
    fieldEmailEng: "collectionAppointmentFieldEn",
    fieldEmailAr: "collectionAppointmentFieldAr",
    emailsTo: "collectionAppointmentToEmail",
    emailsCC: "collectionAppointmentCCEmails",
  },
};

export const messageParameters = {
  scheduleNotification: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Date", value: "date" },
      { key: "Time", value: "time" },
      { key: "Branch Name", value: "branchName" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Service", value: "service" },
      { key: "Location link", value: "locationLink" },
      { key: "Docs link", value: "docsLink" },
      { key: "Vehicle Plate Number", value: "plateNumber" },
    ],
    email: [
      { key: "Select...", value: "select" },
      { key: "Date", value: "date" },
      { key: "Time", value: "time" },
      { key: "Branch Name", value: "branchName" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Service", value: "service" },
      { key: "Location link", value: "locationLink" },
      { key: "Docs link", value: "docsLink" },
      { key: "Vehicle Plate Number", value: "plateNumber" },
    ],
  },
  scheduleNotificationWithCareemPromo: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Date", value: "date" },
      { key: "Time", value: "time" },
      { key: "Branch Name", value: "branchName" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Service", value: "service" },
      { key: "Location link", value: "locationLink" },
      { key: "Docs link", value: "docsLink" },
      { key: "Vehicle Plate Number", value: "plateNumber" },
      { key: "Careem Promo Code", value: "careemPromoCode" },
    ],
  },
  fastCollect: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Make", value: "make" },
      { key: "Model", value: "model" },
      { key: "Car Number Plate", value: "plateNumber" },
      { key: "Redirect link", value: "redirectLink" },
    ],
  },
  otpNotification: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Otp", value: "otp" },
    ],
    email: [
      { key: "Select...", value: "select" },
      { key: "Otp", value: "otp" },
    ],
  },
  rescheduleNotification: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Date", value: "date" },
      { key: "Time", value: "time" },
      { key: "Branch Name", value: "branchName" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Service", value: "service" },
      { key: "Location link", value: "locationLink" },
      { key: "Docs link", value: "docsLink" },
      { key: "Vehicle Plate Number", value: "plateNumber" },
    ],
    email: [
      { key: "Select...", value: "select" },
      { key: "Date", value: "date" },
      { key: "Time", value: "time" },
      { key: "Branch Name", value: "branchName" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Service", value: "service" },
      { key: "Location link", value: "locationLink" },
      { key: "Docs link", value: "docsLink" },
      { key: "Vehicle Plate Number", value: "plateNumber" },
    ],
  },
  cancelNotification: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Date", value: "date" },
      { key: "Time", value: "time" },
      { key: "Branch Name", value: "branchName" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Service", value: "service" },
      { key: "Location link", value: "locationLink" },
      { key: "Docs link", value: "docsLink" },
      { key: "Vehicle Plate Number", value: "plateNumber" },
    ],
    email: [
      { key: "Select...", value: "select" },
      { key: "Date", value: "date" },
      { key: "Time", value: "time" },
      { key: "Branch Name", value: "branchName" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Service", value: "service" },
      { key: "Location link", value: "locationLink" },
      { key: "Docs link", value: "docsLink" },
      { key: "Vehicle Plate Number", value: "plateNumber" },
    ],
  },
  otherCarNotification: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Date", value: "date" },
      { key: "Time", value: "time" },
      { key: "Branch Name", value: "branchName" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Service", value: "service" },
      { key: "Location link", value: "locationLink" },
      { key: "Docs link", value: "docsLink" },
      { key: "Vehicle Plate Number", value: "plateNumber" },
    ],
    email: [
      { key: "Select...", value: "select" },
      { key: "Date", value: "date" },
      { key: "Time", value: "time" },
      { key: "Branch Name", value: "branchName" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "Brand", value: "otherBrand" },
      { key: "Model", value: "otherModel" },
      { key: "Year", value: "year" },
      { key: "Title", value: "title" },
      { key: "Email", value: "email" },
      { key: "Phone Number", value: "phoneNumber" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Service", value: "service" },
      { key: "Location link", value: "locationLink" },
      { key: "Docs link", value: "docsLink" },
      { key: "Vehicle Plate Number", value: "plateNumber" },
    ],
  },
  estimationRequest: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Brand", value: "make" },
      { key: "Model", value: "model" },
      { key: "Car Plate ID", value: "licencePlate" },
      { key: "Url", value: "url" },
    ],
  },
  closeEstimationRequest: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Car Brand", value: "make" },
      { key: "Car Model", value: "model" },
      { key: "Car Year", value: "year" },
      { key: "Car Plate Number", value: "licencePlate" },
      { key: "Url", value: "url" },
    ],
  },
  estimationAppointment: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Date", value: "date" },
      { key: "Time", value: "time" },
      { key: "Branch Name", value: "branchName" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Service", value: "service" },
      { key: "Location link", value: "locationLink" },
      { key: "Docs link", value: "docsLink" },
      { key: "Vehicle Plate Number", value: "plateNumber" },
    ],
    email: [
      { key: "Select...", value: "select" },
      { key: "Date", value: "date" },
      { key: "Time", value: "time" },
      { key: "Branch Name", value: "branchName" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Service", value: "service" },
      { key: "Location link", value: "locationLink" },
      { key: "Docs link", value: "docsLink" },
      { key: "Vehicle Plate Number", value: "plateNumber" },
    ],
  },
  estimationComment: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Brand", value: "make" },
      { key: "Model", value: "model" },
      { key: "Car Plate ID", value: "licencePlate" },
      { key: "Url", value: "url" },
    ],
    email: [
      { key: "Select...", value: "select" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Brand", value: "brand" },
      { key: "Model", value: "model" },
      { key: "Car Plate ID", value: "licencePlate" },
      { key: "Url", value: "url" },
    ],
  },
  estimationApproval: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Brand", value: "make" },
      { key: "Model", value: "model" },
      { key: "Year", value: "year" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Vehicle Plate Number", value: "licencePlate" },
      { key: "Url", value: "url" },
    ],
    email: [
      { key: "Select...", value: "select" },
      { key: "Brand", value: "make" },
      { key: "Model", value: "model" },
      { key: "Year", value: "year" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Vehicle Plate Number", value: "licencePlate" },
      { key: "Url", value: "url" },
    ],
  },
  express: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Service", value: "service" },
      { key: "Time", value: "time" },
      { key: "Date", value: "date" },
      { key: "Branch Name", value: "branchName" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "Location link", value: "locationLink" },
    ],
    email: [
      { key: "Select...", value: "select" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Service", value: "service" },
      { key: "Time", value: "time" },
      { key: "Date", value: "date" },
      { key: "Branch Name", value: "branchName" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "Location link", value: "locationLink" },
    ],
  },
  paymentAddon: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "User Name", value: "username" },
      { key: "Order Id", value: "orderId" },
      { key: "Payment Time", value: "time" },
      { key: "Payment Date", value: "date" },
      { key: "Total Paid Amount", value: "amount" },
      { key: "Branch Name", value: "branchName" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "Location link", value: "locationLink" },
      { key: "Order History Link", value: "url" },
    ],
    email: [
      { key: "Select...", value: "select" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Order Id", value: "orderId" },
      { key: "Payment Time", value: "time" },
      { key: "Payment Date", value: "date" },
      { key: "Total Paid Amount", value: "amount" },
      { key: "Branch Name", value: "branchName" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "Location link", value: "locationLink" },
    ],
  },
  paymentOffer: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "User Name", value: "username" },
      { key: "Payment Time", value: "time" },
      { key: "Payment Date", value: "date" },
      { key: "Total Paid Amount", value: "amount" },
      { key: "Branch Name", value: "branchName" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "Location link", value: "locationLink" },
      { key: "Order History Link", value: "url" },
    ],
    email: [
      { key: "Select...", value: "select" },
      { key: "User Name", value: "username" },
      { key: "Payment Time", value: "time" },
      { key: "Payment Date", value: "date" },
      { key: "Total Paid Amount", value: "amount" },
      { key: "Branch Name", value: "branchName" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "Location link", value: "locationLink" },
    ],
  },
  paymentUpsell: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "User Name", value: "username" },
      { key: "Payment Time", value: "time" },
      { key: "Order Id", value: "orderId" },
      { key: "Payment Date", value: "date" },
      { key: "Total Paid Amount", value: "amount" },
      { key: "Branch Name", value: "branchName" },
      { key: "Location link", value: "locationLink" },
      { key: "Order History Link", value: "url" },
    ],
    email: [
      { key: "Select...", value: "select" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Order Id", value: "orderId" },
      { key: "Payment Time", value: "time" },
      { key: "Payment Date", value: "date" },
      { key: "Total Paid Amount", value: "amount" },
      { key: "Branch Name", value: "branchName" },
      { key: "Location link", value: "locationLink" },
    ],
  },
  expressCustomerNotification: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Date", value: "date" },
      { key: "Time", value: "time" },
      { key: "Branch Name", value: "branchName" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Service", value: "service" },
      { key: "Location link", value: "locationLink" },
      { key: "Docs link", value: "docsLink" },
      { key: "Vehicle Plate Number", value: "plateNumber" },
    ],
  },
  expressComment: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Brand", value: "make" },
      { key: "Model", value: "model" },
      { key: "Car Plate ID", value: "licencePlate" },
      { key: "Url", value: "url" },
    ],
  },
  expressConfiramtionNotification: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Service Name", value: "service" },
      { key: "Branch Name", value: "branchName" },
      { key: "Vehicle Plate Number", value: "plateNumber" },
      { key: "Date", value: "date" },
      { key: "Time", value: "time" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "Location link", value: "locationLink" },
      { key: "Careem Promo Code", value: "careemPromoCode" },
    ],
  },
  finalInvoice: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Branch Name", value: "branchName" },
      { key: "Mobile Number", value: "phoneNumber" },
      { key: "Order History Link", value: "url" },
      { key: "Total Paid Amount", value: "amount" },
    ],
  },
  refundContent: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Branch Name", value: "branchName" },
      { key: "Mobile Number", value: "phoneNumber" },
      { key: "Order History Link", value: "url" },
      { key: "Total Paid Amount", value: "amount" },
    ],
  },
  recommendedOfferContent: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Full Name", value: "fullName" },
      { key: "Brand", value: "make" },
      { key: "Model", value: "model" },
      { key: "Vehicle Plate Number", value: "licencePlate" },
      { key: "Branch Name", value: "branchName" },
      { key: "Offer Name", value: "offerName" },
      { key: "Price", value: "price" },
      { key: "Phone number", value: "phoneNumber" },
      { key: "Url", value: "url" },
    ],
  },
  fastCollectSatisfiedSms: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Fist Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Brand", value: "make" },
      { key: "Model", value: "model" },
      { key: "Vehicle Plate Number", value: "licencePlate" },
      { key: "Branch Name", value: "branchName" },
      { key: "Service Name", value: "serviceName" },
      { key: "Url", value: "url" },
      { key: "Car pickup date", value: "date" },
      { key: "Car pickup slot", value: "slot" },
    ],
  },
  fastCollectNotSatisfiedSms: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Fist Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Brand", value: "make" },
      { key: "Model", value: "model" },
      { key: "Vehicle Plate Number", value: "licencePlate" },
      { key: "Branch Name", value: "branchName" },
      { key: "Service Name", value: "serviceName" },
      { key: "Url", value: "url" },
    ],
  },
  fastCollectRepairCompletedSms: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Fist Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Brand", value: "make" },
      { key: "Model", value: "model" },
      { key: "Year", value: "year" },
      { key: "Vehicle Plate Number", value: "licencePlate" },
      { key: "Branch Name", value: "branchName" },
      { key: "Url", value: "url" },
    ],
  },
  quoteBookAndPay: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Customer Name", value: "customerName" },
      { key: "Phone number", value: "phoneNumber" },
      { key: "Brand", value: "make" },
      { key: "Model", value: "model" },
      { key: "Year", value: "year" },
      { key: "Branch Name", value: "branchName" },
      { key: "Quote Service", value: "quoteServiceName" },
      { key: "Quote Id Hash", value: "quoteIdHash" },
      { key: "cost", value: "cost" },
    ],
  },
  quoteBookAndPayUserRequest: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Customer Name", value: "customerName" },
      { key: "Phone number", value: "phoneNumber" },
      { key: "Brand", value: "make" },
      { key: "Model", value: "model" },
      { key: "Year", value: "year" },
      { key: "Branch Name", value: "branchName" },
      { key: "Quote Service", value: "quoteServiceName" },
      { key: "Quote Id Hash", value: "quoteIdHash" },
      { key: "Quote Id Hash", value: "quoteIdHash" },
      { key: "Vehicle Plate Number", value: "licencePlate" },
    ],
  },
  subscriptionBuy: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Customer Name", value: "customerName" },
      { key: "Phone number", value: "phoneNumber" },
      { key: "Car Brand", value: "make" },
      { key: "Car Model", value: "model" },
      { key: "Car Year", value: "year" },
      { key: "Car Plate Number", value: "licencePlate" },
      { key: "Branch Name", value: "branchName" },
      { key: "Subscription Name", value: "subscriptionName" },
      { key: "Subscription Amount", value: "subscriptionAmount" },
      { key: "No. of Services (Quota)", value: "quota" },
      { key: "Expiry Date", value: "expiryDate" },
      { key: "Subscription Id", value: "packageId" },
      { key: "Order Id", value: "orderId" },
    ],
  },
  docsVerifiedForCND: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Service Name", value: "service" },
      { key: "Branch Name", value: "branchName" },
      { key: "Vehicle Plate Number", value: "plateNumber" },
      { key: "Date", value: "date" },
      { key: "Time", value: "time" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "Location link", value: "locationLink" },
    ],
  },
  carReceivedAtCenterForCND: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Customer Name", value: "customerName" },
      { key: "Car Brand", value: "make" },
      { key: "Car Model", value: "model" },
      { key: "Car Year", value: "year" },
      { key: "Car Plate Number", value: "licensePlate" },
      { key: "Branch Name", value: "branchName" },
      { key: "Service Name", value: "serviceName" },
      { key: "Collection Address", value: "collectionAddress" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "Order Id", value: "orderId" },
    ],
  },
  scheduleDeliveryForCND: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Customer Name", value: "customerName" },
      { key: "Car Brand", value: "make" },
      { key: "Car Model", value: "model" },
      { key: "Car Year", value: "year" },
      { key: "Car Plate Number", value: "licensePlate" },
      { key: "Branch Name", value: "branchName" },
      { key: "Service Name", value: "serviceName" },
      { key: "Link to Schedule", value: "url" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "Order Id", value: "orderId" },
    ],
  },
  serviceCompleteForCND: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Customer Name", value: "customerName" },
      { key: "Car Brand", value: "make" },
      { key: "Car Model", value: "model" },
      { key: "Car Year", value: "year" },
      { key: "Car Plate Number", value: "licensePlate" },
      { key: "Branch Name", value: "branchName" },
      { key: "Service Name", value: "serviceName" },
      { key: "Delivery Address", value: "deliveryAddress" },
      { key: "Delivery Date", value: "deliveryDate" },
      { key: "Delivery Time", value: "deliveryTime" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "Order Id", value: "orderId" },
    ],
  },
  qmsUnservedCustomers: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Customer Name", value: "customerName" },
      { key: "Car Brand", value: "make" },
      { key: "Car Model", value: "model" },
      { key: "Car Year", value: "year" },
      { key: "Car Plate Number", value: "licensePlate" },
      { key: "Branch Name", value: "branchName" },
      { key: "Service Name", value: "serviceName" },
      { key: "Appointment Id", value: "appointmentDate" },
      { key: "Appointment Id", value: "appointmentTime" },
      { key: "Appointment Id", value: "appointmentHash" },
      { key: "Location Link", value: "locationLink" },
    ],
  },
  collectionAppointmentSms: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Reference Id", value: "collectionHashId" },
      { key: "First Name", value: "firstName" },
      { key: "Last Name", value: "lastName" },
      { key: "Phone Number", value: "phoneNumber" },
      { key: "Car Brand", value: "make" },
      { key: "Car Model", value: "model" },
      { key: "Car Year", value: "year" },
      { key: "Car Plate Number", value: "licensePlate" },
      { key: "Branch Name", value: "branchName" },
      { key: "Delivery Time", value: "deliveryTime" },
    ],
  },
  careemCodeGenerationSms: {
    sms: [
      { key: "Select...", value: "select" },
      { key: "Phone Number", value: "phoneNumber" },
      { key: "Careem Code", value: "careemCode" },
    ],
  },

  serviceAdvisorNotifications: {
    email: [
      { key: "Select...", value: "select" },
      { key: "Advisor Name", value: "name" },
      // { key: "Purchased Service", value: "service" },
      { key: "Total Paid Price", value: "price" },
      { key: "Customer Name", value: "customerName" },
      { key: "Order Id", value: "orderId" },
      { key: "Customer Number", value: "customerNumber" },
    ],
  },
  FastCollectSatisfiedCustomerServiceAdvisor: {
    email: [
      { key: "Select...", value: "select" },
      { key: "Advisor Name", value: "name" },
      { key: "Customer Name", value: "customerName" },
      { key: "Customer Number", value: "customerNumber" },
      { key: "Schedule Date", value: "scheduleDate" },
      { key: "Slot", value: "slot" },
      { key: "Branch Name", value: "branchName" },
      { key: "Car Plate Number", value: "carPlateNumber" },
    ],
  },
  FastCollectNotSatisfiedCustomerServiceAdvisor: {
    email: [
      { key: "Select...", value: "select" },
      { key: "Advisor Name", value: "name" },
      { key: "Customer Name", value: "customerName" },
      { key: "Customer Number", value: "customerNumber" },
      { key: "Customer Reason", value: "reason" },
      { key: "Branch Name", value: "branchName" },
      { key: "Car Plate Number", value: "carPlateNumber" },
    ],
  },
  forgotPasswordEmail: {
    email: [
      { key: "Select...", value: "select" },
      { key: "Full Name", value: "fullName" },
      { key: "Reset Link", value: "url" },
      { key: "Mobile Number", value: "phoneNumber" },
      { key: "Email", value: "email" },
    ],
  },
  setPasswordScreenForAdmin: {
    email: [
      { key: "Select...", value: "select" },
      { key: "Full Name", value: "fullName" },
      { key: "Mobile Number", value: "phoneNumber" },
      { key: "Password Screen Link", value: "url" },
    ],
  },
  orderRefundRequestEmail: {
    email: [
      { key: "Select...", value: "select" },
      { key: "Full Name", value: "fullName" },
      { key: "Mobile Number", value: "phoneNumber" },
      { key: "Order Id", value: "orderHashId" },
      { key: "Amount", value: "amount" },
    ],
  },
  orderRefundEmail: {
    email: [
      { key: "Select...", value: "select" },
      { key: "Full Name", value: "fullName" },
      { key: "Mobile Number", value: "phoneNumber" },
      { key: "Order Id", value: "orderHashId" },
      { key: "Refund  reason", value: "reason" },
      { key: "Amount", value: "amount" },
      { key: "Refund At", value: "refundAt" },
    ],
  },
  eInvoiceErrorNotification: {
    email: [
      { key: "Select...", value: "select" },
      { key: "Order ID Hash", value: "orderIdHash" },
      { key: "Customer Name", value: "customerName" },
      { key: "Customer Number", value: "customerNumber" },
      { key: "Error", value: "invoiceError" },
    ],
  },
  vehicleCheckedInNotification: {
    email: [
      { key: "Select...", value: "select" },
      { key: "Phone Number", value: "phoneNumber" },
      { key: "License Plate", value: "licensePlate" },
    ],
  },
  fastDropEmail: {
    email: [
      { key: "Select...", value: "select" },
      { key: "Appointment Id", value: "appointmentHash" },
    ],
  },
  collectionAppointmentEmail: {
    email: [
      { key: "Select...", value: "select" },
      { key: "Title", value: "title" },
      { key: "Customer Name", value: "customerName" },
      { key: "Customer Number", value: "customerNumber" },
      { key: "License Plate", value: "licensePlate" },
      { key: "Car Brand", value: "make" },
      { key: "Car Model", value: "model" },
      { key: "Car Year", value: "year" },
      { key: "Schedule Date", value: "scheduleDate" },
      { key: "Slot", value: "slot" },
    ],
  },
};
