import React, { Component, createRef } from "react";
import withRouter from "../../util/withRouter";
import { FormattedMessage, injectIntl } from "react-intl";
import { withAuthContext } from "../../state/AuthContext";
import { Form, Formik } from "formik";
import { Yup } from "../../util";
import {
  cities,
  getQueryData,
  isLtr,
  isValidObjectId,
  sources,
  sources_ar,
} from "../../util/utils";
import _ from "lodash";
import FieldRow from "../shared/FieldRow";
import Auth from "../shared/Auth";
import Layout from "../shared/Layout";
import HeaderTitle from "../shared/HeaderTitle";
import appState from "../../state/AppStateContainer";
import { NotificationManager } from "react-notifications";
import Services from "../../services";
import AppointmentService from "../../services/appointmentService";
import BranchesService from "../../services/branchesService";
import { ActivitiesEnum } from "../../staticData/Activities";
import CheckAccess from "../shared/CheckAccess";
import { getDates } from "../../util";
import ImageProvider from "../shared/ImageProvider";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import moment from "moment";
import ReactLoading from "react-loading";
import Select from "react-select";
import CommentList from "../shared/CommentList";
import CommentModal from "../shared/CommentModal";
import PasswordVerificationModal from "../shared/passwordVerificationModal";
import AppointmentCancelReasonModal from "../shared/AppointmentCancelReasonModal";
import { Button } from "react-bootstrap";
import {
  checkTamaraPaymentStatus,
  createOrder,
  getOrderPayload,
} from "../../util/orderUtil";
import OtpModal from "../shared/OtpModal";

export class AppointmentForm extends Component {
  constructor() {
    super();
    this.formik = createRef();
    this.gender = [
      { id: "Mr", name: "Mr" },
      { id: "Ms", name: "Ms" },
    ];
    this.cities = [{ id: "", name: "Select City" }, ...cities];
    this.languages = [
      { id: "en", name: "English" },
      { id: "ar", name: "Arabic" },
    ];
    this.dayChange = this.dayChange.bind(this);
    this.sources = sources;
    this.sources_ar = sources_ar;
    this.tamaraDetails =
      localStorage.getItem("tamaraDetails") &&
      JSON.parse(localStorage.getItem("tamaraDetails"));
    this.paymentStatus = getQueryData("paymentStatus");
    this.orderId = getQueryData("orderId");
    this.appointmentId = getQueryData("appointmentId");
    this.hasPaymentStatusFetched = false;
  }

  state = {
    data: {
      expressComments: [],
      expressDocuments: [],
    },
    carBrands: [],
    carModels: [],
    carYears: [],
    serviceProviders: [{ id: "", name: "Select Branch" }],
    servicesList: [],
    trackSelectedService: null,
    selectedBranchId: "",
    selectedServiceId: "",
    selectedDay: "",
    slotsToRender: [],
    selectedTimeslot: [],
    closedDays: [],
    holidays: [],
    isServiceNotSelected: false,
    isDateNotSelected: false,
    isTimeslotsNotAvailable: false,
    lighboxSrc: "",
    statusFilter: "all",
    approvalFilter: "all",
    loading: true,
    disableInput: false,
    paramsId: "",
    isUploadImages: false,
    timeSlotsLoading: false,
    serviceIdForCarOwnBy: "",
    carOwnBy: "",
    bookingAppointmentFor: "",
    showCommentsList: false,
    addComment: "",
    verifyPassword: false,
    showReasonModal: false,
    isOtherSource: false,
    isServiceAdvisor: false,
    isPaid: false,
    showOtpModal: false,
    showOtpError: false,
  };

  async componentDidMount() {
    try {
      this.setState({
        loading: true,
        isServiceAdvisor: appState?.state?.token?.isServiceAdvisor,
      });

      if (this.tamaraDetails) {
        this.orderId = this.tamaraDetails?.orderId;
        this.appointmentId = this.tamaraDetails?.appointmentId;
      }

      if (
        this.paymentStatus?.toLocaleLowerCase() === "approved" ||
        this.paymentStatus?.toLocaleLowerCase() === "failed"
      ) {
        this.tamaraDetails = undefined;
        await this.proceedAfterTamaraPayment();
      }

      this.subscription = appState.emitter.addListener("onLanguageChange", () =>
        this.formik.validateForm()
      );

      await this.setUpForm();

      this.setState({
        paramsId: new URLSearchParams(window.location.search).get("id"),
      });
      if (
        this.paymentStatus?.toLocaleLowerCase() === "canceled" ||
        this.paymentStatus?.toLocaleLowerCase() === "failed" ||
        this.tamaraDetails
      ) {
        this.props.match.params.id = this.appointmentId;
      } else {
        this.props.match.params.id =
          this.props.match.params.id || this.state.paramsId;
      }

      if (
        this.props.match.params.id &&
        isValidObjectId(this.props.match.params.id)
      ) {
        if (
          this.paymentStatus?.toLocaleLowerCase() === "canceled" ||
          this.paymentStatus?.toLocaleLowerCase() === "failed" ||
          this.tamaraDetails
        ) {
          this.setState({ disableInput: false });

          if (this.props.location.state?.appointment) {
            this.setState({
              data: this.props.location.state?.appointment || [],
            });
          }
        } else {
          this.setState({ disableInput: true });
        }

        const appointment = await this.fetchAppointment();
        const service = appointment.services[0].serviceId;

        await Promise.all([
          this.getModels(appointment.carId?.brand?._id),
          this.getYears(
            appointment.carId?.brand?.value_en,
            appointment.carId?.product?.value_en
          ),
          this.getServices({
            target: { value: appointment.branchId._id },
          }),
          this.getSlotsForService(
            appointment?.branchId._id,
            appointment?.services[0]?.serviceId?._id,
            appointment?.date
          ),
          this.decideShopClosedDays(appointment.branchId._id),
        ]);
        const carOwnBy = await AppointmentService.getCarOwnBy(service._id);
        const bookingAppointmentFor =
          await AppointmentService.getBookingAppointmentFor(
            appointment.requiredDocuments.carOwnBy,
            service._id
          );

        this.setState({
          carOwnBy,
          bookingAppointmentFor,
          selectedDay: new Date(appointment.date),
          trackSelectedService: {
            id: service._id,
            en: service.name.value_en,
            ar: service.name.value_ar,
          },
          selectedTimeslot: this.state.data.services[0].slot,
        });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false });
    } finally {
      this.setState({ loading: false });
    }
  }

  componentWillUnmount() {
    this.subscription?.remove();
  }

  fetchAppointment = async () => {
    try {
      this.setState({
        loading: true,
      });
      const appointment = await AppointmentService.getAppointment(
        this.props.match.params.id
      );
      this.setState(
        {
          data: appointment,
          isOtherSource: this.sources?.find(
            (item) => item.id === appointment?.source
          )
            ? false
            : true,
          isPaid: appointment?.isPaid ? true : false,
        }

        // () => {
        //   this.formik.resetForm();
        //   this.setState({ loading: false });
        // }
      );
      await this.getProviders({
        target: {
          value: appointment?.city,
        },
      });
      if (
        this.paymentStatus?.toLocaleLowerCase() === "canceled" ||
        this.paymentStatus?.toLocaleLowerCase() === "failed" ||
        this.tamaraDetails
      ) {
        await this.deleteOrderAndAppointmentOnPaymentCancellation();
        this.handleFailurePayment({
          id: "transaction.canceled",
          defaultMessage: "Transaction has been canceled",
        });
        localStorage.removeItem("tamaraDetails");
        this.props.navigate(`/appointment`, {
          state: {
            appointment,
          },
        });
      }
      return appointment;
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({
        loading: false,
      });
    }
  };

  async deleteOrderAndAppointmentOnPaymentCancellation() {
    try {
      await AppointmentService.deleteOrderAndAppointment({
        orderId: this.orderId,
        appointmentId: this.appointmentId,
      });
    } catch (err) {
      console.log(err);
    }
  }

  handleBranchHolidays = async (branchId) => {
    const ocassions = await BranchesService.getOcassions(branchId);
    if (ocassions) {
      let holidaysDates = [];
      ocassions &&
        ocassions?.forEach((o) => {
          if (o.isHoliday) {
            const datesArr = getDates(o.dateRange.from, o.dateRange.to);
            holidaysDates = holidaysDates.concat(datesArr);
          }
        });
      return holidaysDates;
    }
  };

  async setUpForm() {
    const carBrands = await AppointmentService.getBrands();
    this.setState({ carBrands });

    let providers = await AppointmentService.getProviders();
    let filteredCitiesArray = [{ id: "", name: "Select City" }];
    providers.map((sp, i) => {
      filteredCitiesArray.push({ id: sp.city, name: sp.city });
    });
    filteredCitiesArray = filteredCitiesArray.filter(
      (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
    );
    this.cities = filteredCitiesArray;
  }

  async getModels(e) {
    let carModels = await AppointmentService.getModels(e);
    this.setState({ carModels: carModels });
  }

  async getYears(brand, e) {
    let carYears = await AppointmentService.getCarYears(brand, e);
    this.setState({ carYears: carYears });
  }

  async getProviders(e) {
    this.setState({
      trackSelectedService: null,
      servicesList: [],
      selectedDay: "",
      slotsToRender: [],
      loading: true,
    });

    var selectedCity = e.target.value;
    let providers = await AppointmentService.getProviders();
    let providersCollection = [{ id: "", name: "Select Branch" }];
    providers &&
      providers?.forEach((p) => {
        if (p.city == selectedCity) {
          providersCollection.push({
            id: p._id,
            name: p?.name?.value_en || p?.name,
            shopTimings: p.shopTimings,
          });
        }
      });
    this.setState({ serviceProviders: providersCollection, loading: false });
  }

  async getServices(e) {
    this.setState({
      trackSelectedService: null,
      servicesList: [],
      selectedDay: "",
      slotsToRender: [],
      selectedBranchId: e.target.value,
      loading: true,
    });

    if (!isValidObjectId(e?.target?.value)) {
      return this.setState({
        servicesList: [],
      });
    }

    let services = await Services.getServicesList(e.target.value);
    this.setState({ servicesList: services });

    if (
      this.paymentStatus?.toLocaleLowerCase() === "canceled" ||
      this.paymentStatus?.toLocaleLowerCase() === "failed" ||
      this.tamaraDetails
    ) {
      const serviceIds = new Set(
        this.state.data?.services?.map((d) => d.serviceId._id)
      );
      const filteredService = services?.reduce((acc, category) => {
        const filteredData = category.data.filter((service) =>
          serviceIds.has(service.id)
        );
        return acc.concat(filteredData);
      }, []);
      this.setState({
        serviceItem: this.serviceItemHandler(filteredService),
      });
      this.tamaraDetails = undefined;

      if (this.state.serviceItem && this.state.serviceItem[0]?.price) {
        this.setState({
          isPaid: true,
        });
      }
    }

    this.decideShopClosedDays(this.state.selectedBranchId);
  }

  async decideShopClosedDays(branchId) {
    const onUpdate = this.props.match.params.id;
    let providersToFilter = this.state.serviceProviders;

    if (onUpdate) {
      providersToFilter = await AppointmentService.getProviders();
    }

    let forCalender = [];
    const shopsMeta = providersToFilter?.find((provider) =>
      !onUpdate ? provider.id : provider._id == branchId
    )?.shopTimings;
    let shopClosings = shopsMeta?.map(({ isClosed }) => isClosed);

    let displaceSunday = shopClosings?.pop();
    shopClosings && shopClosings.unshift(displaceSunday);

    shopClosings &&
      shopClosings.forEach((closed, index) => {
        if (closed) {
          forCalender.push(index);
        }
      });

    const branchHoliDays = await this.handleBranchHolidays(branchId);
    this.setState({
      holidays: branchHoliDays,
      closedDays: forCalender,
      loading: !this.props.match.params.id && false,
    });
  }

  dayChange(day, { selected }) {
    if (!selected) {
      this.setState({
        slotsToRender: [],
        selectedTimeslot: "",
      });
    }
    this.setState({
      isDateNotSelected: false,
      isTimeslotsNotAvailable: false,
      selectedDay: day,
    });
    this.getSlotsForService(
      this.state.selectedBranchId,
      this.state.trackSelectedService?.id?._id ||
        this.state.trackSelectedService?.id,
      day
    );
  }

  async showSelectedServices(e, service) {
    if (!e?.target.checked) this.setState({ isPaid: false });
    this.setState({ selectedDay: "" });

    const state = {
      selectedDay: "",
      isUploadImages: service.isUploadImages,
      isServiceNotSelected: false,
      isTimeslotsNotAvailable: false,
    };

    if (e.target.checked) {
      state.trackSelectedService = {
        id: service.id,
        en: service.nameEn,
        ar: service.nameAr,
      };
    }

    const carOwnByData = await AppointmentService.getCarOwnBy(service.id);
    const bookingAppointmentForData =
      await AppointmentService.getBookingAppointmentFor(
        carOwnByData[0]?._id,
        service.id
      );
    this.setState({
      ...state,
      carOwnBy: carOwnByData,
      bookingAppointmentFor: bookingAppointmentForData,
      serviceIdForCarOwnBy: service.id,
      isPaid: service?.price ? true : false,
      loading: false,
      serviceItem: this.serviceItemHandler(service),
    });
  }

  serviceItemHandler(service) {
    const mapServiceItem = (item) => ({
      _id: item?.id,
      name: {
        value_en: item?.nameEn,
        value_ar: item?.nameAr,
      },
      price: item?.price,
      quantity: 1,
    });

    if (Array.isArray(service)) {
      return service.map(mapServiceItem);
    } else {
      return [mapServiceItem(service)];
    }
  }

  // async getBookingAppointmentForHandler(id) {
  //   const bookingAppointmentFor =
  //     await AppointmentService.getBookingAppointmentFor(
  //       id,
  //       this.state.serviceIdForCarOwnBy
  //     );
  //   this.setState({
  //     bookingAppointmentFor,
  //   });
  // }

  async getSlotsForService(branchId, serviceId, day) {
    this.setState({
      slotsToRender: [],
      selectedServiceId: serviceId,
      timeSlotsLoading: true,
    });
    const slotsForService = await AppointmentService.getSlots(
      branchId,
      serviceId,
      day
    );
    this.setState({
      timeSlotsLoading: false,
    });
    if (slotsForService.length) {
      this.setState({ slotsToRender: slotsForService });
    }

    if (!slotsForService?.length) {
      this.setState({
        isTimeslotsNotAvailable: true,
      });
    } else {
      this.setState({ isTimeslotsNotAvailable: false });
    }
  }

  checkValidations() {
    const selectedService = this.state.trackSelectedService;
    const selectedDate = this.state.selectedDay;
    const selectedSlot = this.state.selectedTimeslot;

    if (!selectedService) this.setState({ isServiceNotSelected: true });
    else if (!selectedDate) this.setState({ isDateNotSelected: true });

    if (selectedService && selectedDate && selectedSlot) return true;
    else return false;
  }

  createCarNickName = (values) =>
    values.carNickName ||
    values.firstName + " " + (values.otherModel || values.model);

  getLocalizedOptions = (object) => {
    let value = {};
    if (appState.state.language === "en") {
      value = {
        label: object.value_en,
        value: object._id,
      };
    } else {
      value = {
        label: object.value_ar,
        value: object._id,
      };
    }
    return value;
  };

  handleCarPlateNum = (e) => {
    if (!e.target.value.length) return;
    e.target.value = e.target.value.slice(0, 4).replace(/[^0-9]/g, "");
  };

  isExtensionPDF = (fileName) => {
    const extension = fileName?.split(".").pop()?.toLowerCase();
    return extension === "pdf";
  };

  async viewFile(pdf) {
    const file = await Services.downloadFile(pdf, "view");
    const url = URL.createObjectURL(file);
    window.open(url);
  }

  async handleSubmitComments(comment) {
    try {
      this.setState({
        loading: true,
      });
      if (this.props.match.params.id)
        await Services.addExpressComment(comment, this.props.match.params.id);
      this.setState({ addComment: "" });

      await this.fetchAppointment();

      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
    } catch (e) {
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.message", "error.unexpectedError"),
          defaultMessage: e?.response?.data?.message,
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async confirmedExpressAppointment() {
    try {
      if (this.props.match.params.id) {
        await AppointmentService.confirmExpressAppointment(
          this.props.match.params.id
        );
      }

      await this.fetchAppointment();

      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.operationSuccessful",
          defaultMessage: "Operation Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );
    } catch (err) {
      console.log("err", err);
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(err, "response.data.message", "error.unexpectedError"),
          defaultMessage: err?.response?.data?.message,
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    }
  }

  async verificationHandler(values) {
    this.setState({ loading: true });
    try {
      const verificationResponse = await Services.verifyPassword(values);
      NotificationManager.success(
        this.props.intl.formatMessage({
          id: "global.verificationsuccessful",
          defaultMessage: "Verification Successful",
        }),
        this.props.intl.formatMessage({
          id: "global.success",
          defaultMessage: "Success",
        })
      );

      if (verificationResponse.statusCode === 200) {
        this.setState({
          verifyPassword: false,
          showReasonModal: true,
        });
      }

      // await Services.refundOrder(data?._id);
      // this.setState({
      //   verifyPassword: null,
      // });
      // NotificationManager.success(
      //   this.props.intl.formatMessage({
      //     id: "order.refundSuccess",
      //     defaultMessage: "Order Refunded Successfully",
      //   }),
      //   this.props.intl.formatMessage({
      //     id: "global.success",
      //     defaultMessage: "Success",
      //   })
      // );
      // setUserVerified(true);
    } catch (e) {
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: e?.response?.data?.response || "error.unexpectedError",
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );

      this.setState({
        verifyPassword: false,
      });
    } finally {
      this.setState({ loading: false });
    }
  }

  async cancelAppointmentHandler(values) {
    this.setState({ loading: true });
    try {
      const data = {
        appointmentId: this.props.match.params.id,
        estimation: this.state.data?.estimationId,
        ...values,
      };
      const cancelResponse = await Services.cancelAppointment(data);
      if (cancelResponse.statusCode === 200) {
        NotificationManager.success(
          this.props.intl.formatMessage({
            id: "appointment.cancelledSuccessfully",
            defaultMessage: "Appointment Cancelled Successfully",
          }),
          this.props.intl.formatMessage({
            id: "global.success",
            defaultMessage: "Success",
          })
        );
        this.props.navigate("/appointments");
      }
    } catch (e) {
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleSubmit(values, { setSubmitting }) {
    setSubmitting(true);
    this.setState({ loading: true });

    const appointmentData = {
      title: values.mrMs,
      email: values.emailAddress,
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber.replaceAll(" ", ""),
      carNumberPlate: values.plateNumber + "-" + values.plateChar,
      brand: values.brand,
      model: values.model,
      otherBrand: values.otherBrand,
      otherModel: values.otherModel,
      carNickName: this.createCarNickName(values),
      year: values.year,
      city: values.city,
      branchId: this.state.selectedBranchId,
      services: [
        {
          serviceId: this.state.trackSelectedService?.id,
          slot: this.state.selectedTimeslot,
        },
      ],
      imageNote: values.imageNotes,
      date: this.state.selectedDay,
      language: values.language,
      carOwnBy: this?.state?.carOwnBy[0]?._id,
      bookingAppointmentFor: values.bookingAppointmentFor,
      source: this.state.isOtherSource ? values.otherSource : values.source,
      needCareemPromo: values.needCareemPromo,
    };

    const image1 = values.image0.name
      ? new File([values.image0], values.image0.name)
      : null;
    const image2 = values.image1.name
      ? new File([values.image1], values.image1.name)
      : null;
    const image3 = values.image2.name
      ? new File([values.image2], values.image2.name)
      : null;
    const image4 = values.image3.name
      ? new File([values.image3], values.image3.name)
      : null;
    const image5 = values.image4.name
      ? new File([values.image4], values.image4.name)
      : null;

    let carimages = [image1, image2, image3, image4, image5];
    appointmentData.images = carimages.filter((img) => img != null);

    try {
      const passStatus = this.checkValidations();
      let response;
      if (passStatus) {
        if (
          (this.state?.isPaid || this.paymentStatus) &&
          this.state?.isServiceAdvisor &&
          !this.state.data?.isPaid
        ) {
          await this.startOrder({ appointmentData });
          return;
        }
        if (!this.props.match.params.id) {
          response = await AppointmentService.scheduleAppointment(
            appointmentData
          );
        } else {
          appointmentData.services[0].newSlot = this.state.selectedTimeslot;
          response = await AppointmentService.rescheduleAppointment(
            appointmentData,
            this.props.match.params.id
          );
        }

        NotificationManager.success(
          this.props.intl.formatMessage({
            id: "global.operationSuccessful",
            defaultMessage: "Operation Successful",
          }),
          this.props.intl.formatMessage({
            id: "global.success",
            defaultMessage: "Success",
          })
        );

        if (this.state.paramsId) {
          this.props.navigate("/dashboard", { isSubmitted: true });
        } else {
          this.props.navigate("/appointments", { isSubmitted: true });
        }
      }
    } catch (e) {
      this.setState({
        loading: false,
      });
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: _.get(e, "response.data.error", "error.unexpectedError"),
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      this.setState({
        loading: false,
      });
      setSubmitting(false);
      this.setState({ loading: false });
    }
  }

  async startOrder({ appointmentData }) {
    try {
      this.setState({
        loading: true,
      });
      let orderCheck = true;
      const { response } = await AppointmentService.scheduleAppointment(
        appointmentData
      );
      orderCheck = response;

      if (orderCheck) {
        const orderPayload = getOrderPayload({
          module: "offer",
          references: orderCheck,
          pathname: this.props.location.pathname,
          serviceItem: this.state.serviceItem,
        });
        const orderStatus = await createOrder({
          orderPayload,
          appointmentId: orderCheck?._id,
          NotificationManager,
          intl: this.props.intl,
        });
        if (orderStatus)
          return this.handleSuccessPayment({
            id: "order.orderSuccessful",
            defaultMessage: "Order Successfully Placed!",
          });
      }
    } catch (e) {
      this.setState({
        loading: false,
      });
    }
  }

  async proceedAfterTamaraPayment() {
    try {
      this.setState({
        loading: true,
      });
      const orderId = getQueryData("id");

      if (
        this.paymentStatus?.toLocaleLowerCase() === "approved" &&
        !this.hasPaymentStatusFetched
      ) {
        this.hasPaymentStatusFetched = true;
        const status = await checkTamaraPaymentStatus({
          orderId,
          NotificationManager,
          intl: this.props.intl,
        });
        if (status) {
          this.props.navigate("/appointments");
          localStorage.removeItem("tamaraDetails");
          return;
        }
      }

      if (this.paymentStatus?.toLocaleLowerCase() === "failed")
        NotificationManager.error(
          this.props.intl.formatMessage({
            id: "error.transactionFailed",
            defaultMessage: "Transaction Failed",
          }),
          this.props.intl.formatMessage({
            id: "global.error",
            defaultMessage: "Error",
          })
        );
    } catch (err) {
      this.setState({
        loading: false,
      });
      console.log(err);
    }
  }

  async handleSuccessPayment({ id, defaultMessage }) {
    NotificationManager.success(
      this.props.intl.formatMessage({
        id,
        defaultMessage,
      }),
      this.props.intl.formatMessage({
        id: "global.success",
        defaultMessage: "Success",
      })
    );
    this.props.navigate("/appointments");
  }
  async handleFailurePayment({ id, defaultMessage }) {
    NotificationManager.error(
      this.props.intl.formatMessage({
        id,
        defaultMessage,
      }),
      this.props.intl.formatMessage({
        id: "global.error",
        defaultMessage: "Error",
      })
    );
  }

  async cancelAppointment() {
    const isServiceAdvisor = appState?.state?.token?.isServiceAdvisor;
    if (isServiceAdvisor) return await this._sendOtpToAdmin();

    this.setState({
      verifyPassword: true,
    });
  }

  async _sendOtpToAdmin() {
    try {
      this.setState({ loading: true });
      const response = await Services.sendOtpToAdmin();
      if (response?.statusCode === 200) this.setState({ showOtpModal: true });
    } catch (err) {
      console.log(err);
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: err?.response?.data?.response || "error.unexpectedError",
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleVerifyUserSubmit(values, from = undefined) {
    if (this?.state?.loading) return;
    try {
      this.setState({
        loading: true,
      });

      const code =
        values.code1.toString() +
        values.code2.toString() +
        values.code3.toString() +
        values.code4.toString();

      if (code === this?.state?.previousOtp)
        return this.setState({
          showOtpError: true,
        });

      this.setState({
        previousOtp: code,
      });

      const response = await Services.verifyAdminOtp(code);

      if (response.statusCode === 200) {
        this.setState({
          showOtpModal: false,
          showReasonModal: true,
        });
      }
    } catch (err) {
      NotificationManager.error(
        this.props.intl.formatMessage({
          id: err?.response?.data?.response || "error.unexpectedError",
          defaultMessage: "An unexpected error has occurred",
        }),
        this.props.intl.formatMessage({
          id: "global.error",
          defaultMessage: "Error",
        })
      );
      this.setState({
        showOtpError: true,
      });
      console.log(err);
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async setShowHandler(showOtpModal) {
    this.setState({
      showOtpModal,
    });
  }
  async setShowErrorHandler(showOtpError) {
    this.setState({
      showOtpError,
    });
  }
  _renderBody(formikProps) {
    const { isSubmitting, setFieldValue, values, touched, errors } =
      formikProps;
    const lang = appState.state.language;
    const { data, selectedServiceId } = this.state;

    return (
      <Layout noLayout={this.props.noLayout} loading={this.state.loading}>
        <PasswordVerificationModal
          onCancel={() => {
            this.setState({
              verifyPassword: false,
            });
          }}
          onSubmit={(values) => this.verificationHandler(values)}
          show={this.state.verifyPassword !== false}
          add={true}
          loading={this.state.loading}
        />

        <AppointmentCancelReasonModal
          onCancel={() => {
            this.setState({
              showReasonModal: false,
            });
          }}
          show={this.state.showReasonModal !== false}
          onSubmit={(values) => this.cancelAppointmentHandler(values)}
          loading={this.state.loading}
        />

        <CommentModal
          show={this.state.addComment != ""}
          onSubmit={this.handleSubmitComments.bind(this)}
          onCancel={() => this.setState({ addComment: "" })}
          formikProps={this.props.formikProps}
          formatMessage={this.props.intl.formatMessage}
        />

        <CommentList
          show={this.state.showCommentsList}
          onCancel={() => {
            this.setState({ showCommentsList: false });
          }}
          comments={data.expressComments}
        />
        <OtpModal
          {...{
            show: this?.state?.showOtpModal,
            setShow: this.setShowHandler.bind(this),
            intl: this.props?.intl,
            handleVerifyUserSubmit: this.handleVerifyUserSubmit.bind(this),
            showError: this?.state?.showOtpError,
            setShowError: this.setShowErrorHandler.bind(this),
            loading: this.state?.loading,
          }}
        />

        <div className="dashboard-table-container card-layout">
          <div className="panel-primary">
            <header className="panel-title">
              {this.props.match.params.id &&
                isValidObjectId(this.props.match.params.id) && (
                  <FormattedMessage
                    id="appointmentCreate.updateAppointment"
                    defaultMessage="Reschedule Appointment"
                  />
                )}
              {!this.props.match.params.id && (
                <FormattedMessage
                  id="appointmentCreate.scheduleAppointment"
                  defaultMessage="Schedule New Appointment"
                />
              )}
            </header>
            <Form>
              <div className="dashboard-row">
                {this.props.match.params.id && (
                  <FieldRow
                    type="text"
                    name="appointmentHash"
                    defaultLabel="Appointment Referece Number"
                    labelId="appointmentCreate.appointmentHash"
                    placeholderId="appointmentCreate.appointmentHash"
                    defaultPlaceholder="Appointment Referece Number"
                    formikProps={formikProps}
                    disabled={this.state.disableInput}
                  />
                )}
                <FieldRow
                  name="mrMs"
                  type="select"
                  defaultLabel="Mr/Ms"
                  labelId="appointmentCreate.mrMs"
                  data={this.gender}
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={this.state.disableInput}
                />
                <FieldRow
                  type="text"
                  name="firstName"
                  defaultLabel="First Name"
                  labelId="appointmentCreate.firstName"
                  placeholderId="appointmentCreate.firstName"
                  defaultPlaceholder="Enter first name"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={this.state.disableInput}
                />
                <FieldRow
                  type="text"
                  name="lastName"
                  defaultLabel="Last Name"
                  labelId="appointmentCreate.lastName"
                  placeholderId="appointmentCreate.lastName"
                  defaultPlaceholder="Enter last name"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={this.state.disableInput}
                />
                {/* <CheckAccess
                  activityId={ActivitiesEnum.schedule_appointment_all}
                  accessOnChange={!this.props.match.params.id}
                > */}
                <FieldRow
                  type="text"
                  name="phoneNumber"
                  defaultLabel="Phone Number"
                  labelId="appointmentCreate.phoneNumber"
                  placeholderId="appointmentCreate.phoneNumber"
                  defaultPlaceholder="Enter phone number"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={this.state.disableInput}
                  customPhone={true}
                />
                {/* </CheckAccess>
                <CheckAccess
                  activityId={ActivitiesEnum.schedule_appointment_all}
                  accessOnChange={!this.props.match.params.id}
                > */}
                <FieldRow
                  type="email"
                  name="emailAddress"
                  defaultLabel="Email Address"
                  labelId="appointmentCreate.emailAddress"
                  placeholderId="appointmentCreate.emailAddress"
                  defaultPlaceholder="Enter email address"
                  formikProps={formikProps}
                  disabled={this.state.disableInput}
                />
                <div className="row">
                  <div className="col-md-2 col-xs-12">
                    <label className="form-label caps block require-flag">
                      <FormattedMessage
                        id="appointmentCreate.brand"
                        defaultMessage="Car Brand"
                      />
                    </label>
                  </div>
                  <div className="input-group col-md-8 col-xs-12">
                    <Select
                      name="brand"
                      options={this.state.carBrands.map((car) => {
                        return {
                          value: car?._id,
                          label: car?.["value_" + lang],
                        };
                      })}
                      placeholder={
                        lang === "en" ? "Select Car Brand" : "حدد طراز ماركة"
                      }
                      value={
                        values.brand
                          ? {
                              value: values.brand,
                              label: values.brand,
                            }
                          : ""
                      }
                      onChange={(c) => {
                        setFieldValue("brand", c.label);
                        setFieldValue("model", "");
                        setFieldValue("year", "");
                        this.getModels(c.value);

                        if (c.label === "OTHER") {
                          setFieldValue("model", c.label);
                          this.getYears(c.label, c.label);
                        } else {
                          this.setState({ carYears: [] });
                        }
                      }}
                      isDisabled={this.state.disableInput}
                    />
                    {touched["brand"] && errors["brand"] && (
                      <p className="text-warning">{errors["brand"]}</p>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-2 col-xs-12">
                    <label className="form-label caps block require-flag">
                      <FormattedMessage
                        id="appointmentCreate.model"
                        defaultMessage="Car Model"
                      />
                    </label>
                  </div>
                  <div className="input-group col-md-8 col-xs-12">
                    <Select
                      name="model"
                      options={this.state.carModels.map((car) => {
                        return {
                          value: car?.["value_" + lang],
                          label: car?.["value_" + lang],
                        };
                      })}
                      placeholder={
                        lang === "en" ? "Select Car Model" : "حدد طراز السيارة"
                      }
                      value={
                        values.model
                          ? {
                              value: values.model,
                              label: values.model,
                            }
                          : ""
                      }
                      onChange={(c) => {
                        setFieldValue("model", c.label);
                        setFieldValue("year", "");
                        this.getYears(values.brand, c.value);
                      }}
                      isDisabled={this.state.disableInput}
                    />
                    {touched["model"] && errors["model"] && (
                      <p className="text-warning">{errors["model"]}</p>
                    )}
                  </div>
                </div>
                {values.brand === "OTHER" ? (
                  <>
                    <FieldRow
                      type="text"
                      name="otherBrand"
                      defaultLabel="Other Brand"
                      labelId="appointmentCreate.otherBrand"
                      placeholderId="appointmentCreate.otherBrand"
                      defaultPlaceholder="Enter Car Brand"
                      formikProps={formikProps}
                      showRequiredFlag={true}
                      disabled={this.state.disableInput}
                    />

                    <FieldRow
                      type="text"
                      name="otherModel"
                      defaultLabel="Other Model"
                      labelId="appointmentCreate.otherModel"
                      placeholderId="appointmentCreate.otherModel"
                      defaultPlaceholder="Enter Car Model"
                      formikProps={formikProps}
                      showRequiredFlag={true}
                      disabled={this.state.disableInput}
                    />
                  </>
                ) : values.model === "OTHER" ? (
                  <FieldRow
                    type="text"
                    name="otherModel"
                    defaultLabel="Other Model"
                    labelId="appointmentCreate.otherModel"
                    placeholderId="appointmentCreate.otherModel"
                    defaultPlaceholder="Enter Car Model"
                    formikProps={formikProps}
                    showRequiredFlag={true}
                    disabled={this.state.disableInput}
                  />
                ) : null}
                <FieldRow
                  type="text"
                  name="carNickName"
                  defaultLabel="Car Nick Name"
                  labelId="appointmentCreate.carNickName"
                  placeholderId="appointmentCreate.carNickName"
                  defaultPlaceholder="Enter car nick name"
                  formikProps={formikProps}
                  disabled={this.state.disableInput}
                />
                <FieldRow
                  name="year"
                  type="select"
                  defaultLabel="Car Year"
                  labelId="appointmentCreate.year"
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  disabled={this.state.disableInput}
                >
                  <option value="">
                    {lang === "en" ? "Select Car Year" : "حدد سنة السيارة"}
                  </option>
                  {this.state.carYears.map((name, index) => {
                    return (
                      <option key={index} value={name}>
                        {name === "Before 2017"
                          ? this.props.intl.formatMessage({
                              id: "appointmentCreate.before",
                              defaultMessage: "Before 2017",
                            })
                          : name}
                      </option>
                    );
                  })}
                </FieldRow>
                <div className="two-fields">
                  <FieldRow
                    type="text"
                    name="plateNumber"
                    defaultLabel="Car Plate Number"
                    labelId="appointmentCreate.platNumber"
                    defaultPlaceholder="5361"
                    placeholderId="appointmentCreate.plateNumberEg"
                    pattern="[0-9]*"
                    onInput={this.handleCarPlateNum}
                    disabled={this.state.disableInput}
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    colSize={4}
                  />
                  <FieldRow
                    type="text"
                    name="plateChar"
                    defaultPlaceholder="S D F / ق د و"
                    placeholderId="appointmentCreate.plateCharEg"
                    formikProps={formikProps}
                    onInput={(e) => {
                      if (!e.target.value) return;
                      const val = e.target.value.split(" ").join("");
                      const finalVal = val.match(/.{1,1}/g).join(" ");
                      return (e.target.value = finalVal
                        .slice(0, 5)
                        .replace(/[0-9]/gi, " "));
                    }}
                    disabled={this.state.disableInput}
                  />
                </div>
                {/* </CheckAccess> */}
                <FieldRow
                  name="city"
                  type="select"
                  defaultLabel="City"
                  labelId="appointmentCreate.cities"
                  data={this.cities}
                  showRequiredFlag={true}
                  formikProps={formikProps}
                  onChange={(e) => {
                    if (e.target.value === "Select City") {
                      setFieldValue("city", "");
                    } else {
                      setFieldValue("city", e.target.value);
                    }
                    setFieldValue("providers", "");
                    this.getProviders(e);
                  }}
                  disabled={this.state.disableInput}
                />

                {
                  <FieldRow
                    type="select"
                    name="providers"
                    defaultLabel="Branch"
                    labelId="appointmentCreate.providers"
                    data={this.state.serviceProviders}
                    showRequiredFlag={true}
                    formikProps={formikProps}
                    onChange={(e) => {
                      if (this.props.match.params.id) return;
                      if (e.target.value == "Select Branch") {
                        setFieldValue("providers", "");
                      } else {
                        setFieldValue("providers", e.target.value);
                      }
                      setFieldValue("source", "");
                      this.getServices(e);
                    }}
                    disabled={this.state.disableInput}
                  >
                    {/* {this.props.match.params.id && data?.branchId ? (
                      <option value={data.branchId?.name?._id}>
                        {data.branchId?.name?.value_en ||
                          data.branchId?.name?.value_en}
                      </option>
                    ) : null} */}
                  </FieldRow>
                }

                {data?.collectionAndDelivery && (
                  <>
                    <FieldRow
                      type="text"
                      name="collectionAddress"
                      defaultLabel="Collection Address"
                      labelId="appointmentCreate.collectionAddress"
                      placeholderId="appointmentCreate.collectionAddress"
                      defaultPlaceholder="Collection Address"
                      formikProps={formikProps}
                      disabled={true}
                    />
                    <FieldRow
                      type="text"
                      name="deliveryAddress"
                      defaultLabel="Delivery Address"
                      labelId="appointmentCreate.deliveryAddress"
                      placeholderId="appointmentCreate.deliveryAddress"
                      defaultPlaceholder="Delivery Address"
                      formikProps={formikProps}
                      disabled={true}
                    />
                  </>
                )}

                {this.props.match.params.id ? (
                  <>
                    <FieldRow
                      name="source"
                      type="select"
                      defaultLabel="How did you hear about us ?"
                      labelId="appointmentCreate.sources"
                      data={
                        this.state?.isOtherSource && data.source
                          ? [
                              {
                                id: "Others",
                                name:
                                  appState.state.language === "en"
                                    ? "Others"
                                    : "آحرون",
                              },
                            ]
                          : appState.state.language === "en"
                          ? this.sources
                          : this.sources_ar
                      }
                      // showRequiredFlag={true}
                      formikProps={formikProps}
                      disabled={this.state.disableInput}
                    />
                    {this.state?.isOtherSource && data.source && (
                      <FieldRow
                        type="text"
                        name="otherSource"
                        defaultLabel="-"
                        labelId="-"
                        placeholderId="appointmentCreate.sources"
                        defaultPlaceholder="How did you hear about us ?"
                        formikProps={formikProps}
                        disabled={this.state.disableInput}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <FieldRow
                      name="source"
                      type="select"
                      defaultLabel="How did you hear about us ?"
                      labelId="appointmentCreate.sources"
                      data={
                        appState.state.language === "en"
                          ? this.sources
                          : this.sources_ar
                      }
                      // showRequiredFlag={true}
                      formikProps={formikProps}
                      onChange={(e) => {
                        if (
                          e.target.value == "Select Source" ||
                          e.target.value == "اختر مصدر"
                        ) {
                          setFieldValue("source", "");
                          this.setState({ isOtherSource: false });
                        } else if (
                          e.target.value == "Others" ||
                          e.target.value === "آحرون"
                        ) {
                          setFieldValue("source", e.target.value);
                          this.setState({ isOtherSource: true });
                        } else {
                          this.setState({ isOtherSource: false });
                          setFieldValue("source", e.target.value);
                        }
                      }}
                      disabled={this.state.disableInput}
                    />
                    {this.state?.isOtherSource && (
                      <FieldRow
                        type="text"
                        name="otherSource"
                        defaultLabel="-"
                        labelId="-"
                        placeholderId="appointmentCreate.sources"
                        defaultPlaceholder="How did you hear about us ?"
                        formikProps={formikProps}
                        disabled={this.state.disableInput}
                      />
                    )}
                  </>
                )}

                {this.props.match.params.id && (
                  <FieldRow
                    type="text"
                    name="createdBy"
                    defaultLabel="Created By / Platform"
                    labelId="appointmentCreate.CreatedBy-Platform"
                    placeholderId="appointmentCreate.CreatedBy-Platform"
                    defaultPlaceholder="Created By / Platform"
                    formikProps={formikProps}
                    disabled={true}
                  />
                )}

                <div className="offeredServices inSchedule">
                  {this.state.servicesList?.length ? (
                    <>
                      <hr className="seperator" />
                      <h5>
                        <FormattedMessage
                          id="appointmentCreate.selectServices"
                          defaultMessage="Select Service"
                        />
                      </h5>
                      <div
                        className="checkbox-group"
                        role="group"
                        aria-labelledby="checkbox-group"
                      >
                        {this.state.servicesList?.map((category, index) => {
                          return (
                            <div key={index}>
                              <p>{category._id.toUpperCase()}</p>
                              {category.data.map((service, index) => {
                                return (
                                  <label key={index}>
                                    <input
                                      type="radio"
                                      name="offerings"
                                      id={service.id}
                                      value={service.id}
                                      onChange={(e) =>
                                        this.showSelectedServices(e, service)
                                      }
                                      disabled={this.state.disableInput}
                                      defaultChecked={
                                        service.id === selectedServiceId
                                      }
                                    />
                                    <p>
                                      {lang === "en"
                                        ? service.nameEn
                                        : service.nameAr}
                                    </p>
                                  </label>
                                );
                              })}
                            </div>
                          );
                        })}
                      </div>
                      {this.state.isServiceNotSelected && (
                        <p className="text-warning">
                          <FormattedMessage
                            id="appointmentCreate.atleastOneService"
                            defaultMessage="Service is required"
                          />
                        </p>
                      )}
                    </>
                  ) : null}

                  {this.state.trackSelectedService && (
                    <>
                      <hr className="seperator" />
                      <div className="appointment-date-sc">
                        <h5>
                          <FormattedMessage
                            id="appointmentCreate.date"
                            defaultMessage="APPOINTMENT DATE"
                          />
                        </h5>
                        <DayPicker
                          name="date"
                          onDayClick={this.dayChange}
                          selected={this.state.selectedDay}
                          disabled={[
                            ...this.state.holidays?.map((d) => new Date(d)),
                            { dayOfWeek: this.state.closedDays },
                            { before: new Date() },
                          ]}
                        />
                        {this.state.isDateNotSelected && (
                          <p className="text-warning">
                            <FormattedMessage
                              id="appointmentCreate.selectDate"
                              defaultMessage="Appointment Date is required"
                            />
                          </p>
                        )}
                      </div>
                    </>
                  )}

                  {this.state.selectedDay ? (
                    <>
                      <div className="selected-services">
                        <p>
                          {this.state.trackSelectedService[lang]}
                          {this.props.match.params.id && (
                            <span>
                              {data?.date &&
                                moment(data?.date).format("MMMM D, YYYY")}
                              {", "}
                              {data.services[0].slot}
                            </span>
                          )}
                        </p>
                      </div>

                      <h5 className="set-time-slot-msg">
                        <FormattedMessage
                          id="appointmentCreate.setServiceTimeSlots"
                          defaultMessage="Select Time Slot"
                        />
                      </h5>
                      <div className="time-radios">
                        {this.state.slotsToRender.length
                          ? this.state.slotsToRender?.map(
                              ({ _id, time }, index) => {
                                return (
                                  <span key={index}>
                                    <input
                                      type="radio"
                                      id={_id}
                                      name="time"
                                      value={time}
                                      onChange={(e) =>
                                        this.setState({
                                          selectedTimeslot: e.target.value,
                                        })
                                      }
                                      checked={
                                        this.state.selectedTimeslot === time
                                      }
                                      required
                                    />
                                    <label htmlFor={_id}>{time}</label>
                                  </span>
                                );
                              }
                            )
                          : this.state.timeSlotsLoading && (
                              <ReactLoading
                                color="silver"
                                type="spin"
                                height="20px"
                                width="20px"
                                className="no-styles"
                              />
                            )}
                      </div>

                      {this.state.isTimeslotsNotAvailable && (
                        <p className="text-warning">
                          <FormattedMessage
                            id="appointmentCreate.noAvailableSlots"
                            defaultMessage="No available slots"
                          />
                        </p>
                      )}
                    </>
                  ) : null}
                </div>

                <hr className="seperator" />

                <CheckAccess
                  activityId={ActivitiesEnum.schedule_appointment_all}
                  accessOnChange={!this.props.match.params.id}
                >
                  {!this.props.match.params.id && this.state.isUploadImages && (
                    <>
                      <FieldRow
                        name="image0"
                        type="file"
                        image={true}
                        cameraIcon={true}
                        showThumbnail={true}
                        defaultLabel="Car Image 1"
                        labelId="appointmentCreate.carImage1"
                        placeholderId="appointmentCreate.selectImage"
                        defaultPlaceholder="Select Image"
                        aspectRatio={3000 / 2000}
                        imageSize="W:3000 x H:2000"
                        formikProps={formikProps}
                        disabled={this.state.disableInput}
                      />
                      <FieldRow
                        name="image1"
                        type="file"
                        image={true}
                        cameraIcon={true}
                        showThumbnail={true}
                        defaultLabel="Car Image 2"
                        labelId="appointmentCreate.carImage2"
                        placeholderId="appointmentCreate.selectImage"
                        defaultPlaceholder="Select Image"
                        aspectRatio={3000 / 2000}
                        imageSize="W:3000 x H:2000"
                        formikProps={formikProps}
                        disabled={this.state.disableInput}
                      />
                      <FieldRow
                        name="image2"
                        type="file"
                        image={true}
                        cameraIcon={true}
                        showThumbnail={true}
                        defaultLabel="Car Image 3"
                        labelId="appointmentCreate.carImage3"
                        placeholderId="appointmentCreate.selectImage"
                        defaultPlaceholder="Select Image"
                        aspectRatio={3000 / 2000}
                        imageSize="W:3000 x H:2000"
                        formikProps={formikProps}
                        disabled={this.state.disableInput}
                      />
                      <FieldRow
                        name="image3"
                        type="file"
                        image={true}
                        cameraIcon={true}
                        showThumbnail={true}
                        defaultLabel="Car Image 4"
                        labelId="appointmentCreate.carImage4"
                        placeholderId="appointmentCreate.selectImage"
                        defaultPlaceholder="Select Image"
                        aspectRatio={3000 / 2000}
                        imageSize="W:3000 x H:2000"
                        formikProps={formikProps}
                        disabled={this.state.disableInput}
                      />
                      <FieldRow
                        name="image4"
                        type="file"
                        image={true}
                        cameraIcon={true}
                        showThumbnail={true}
                        defaultLabel="Car Image 5"
                        labelId="appointmentCreate.carImage5"
                        placeholderId="appointmentCreate.selectImage"
                        defaultPlaceholder="Select Image"
                        aspectRatio={3000 / 2000}
                        imageSize="W:3000 x H:2000"
                        formikProps={formikProps}
                        disabled={this.state.disableInput}
                      />
                    </>
                  )}

                  {data.images?.length ? (
                    <div className="appointment-images">
                      {data.images?.map((img, index) => {
                        return (
                          <ImageProvider
                            key={index}
                            imageSrc={img}
                            onClick={() => {
                              this.setState({ lighboxSrc: img });
                            }}
                          />
                        );
                      })}
                    </div>
                  ) : null}

                  <FieldRow
                    type="textarea"
                    name="imageNotes"
                    defaultLabel="Image Notes"
                    labelId="appointmentCreate.imageNotes"
                    placeholderId="appointmentCreate.imageNotes"
                    defaultPlaceholder="Enter Notes"
                    formikProps={formikProps}
                    disabled={this.state.disableInput}
                  />

                  <FieldRow
                    type="checkbox"
                    name="needCareemPromo"
                    labelId="appointmentCreate.needCareemPromo"
                    defaultLabel="Need Careem Promo"
                    formikProps={formikProps}
                    showRequiredFlag={false}
                  />

                  {this.state.trackSelectedService && (
                    <div className="Docs">
                      {/* <FieldRow
                        type="select"
                        name="carOwnBy"
                        defaultLabel="Car Own By"
                        labelId="appointmentCreate.carOwnby"
                        showRequiredFlag={true}
                        formikProps={formikProps}
                        onChange={(e) => {
                          if (e.target.value) {
                            setFieldValue("carOwnBy", e.target.value);
                            this.getBookingAppointmentForHandler(
                              e.target.value
                            );
                          }
                        }}
                        disabled={this.state.disableInput}
                      >
                        <option value="" defaultValue="Please Choose">
                          {this.props.intl.formatMessage({
                            id: "appointmentCreate.pleaseChoose",
                            defaultMessage: "Please Choose",
                          })}
                        </option>
                        {this.state.carOwnBy &&
                          this.state.carOwnBy.map(({ name, _id }, i) => (
                            <option value={_id} key={i}>
                              {isLtr() ? name?.value_en : name?.value_ar}
                            </option>
                          ))}
                      </FieldRow> */}
                      <FieldRow
                        type="select"
                        name="bookingAppointmentFor"
                        defaultLabel="Booking Appointment For"
                        labelId="appointmentCreate.bookingAppointmentFor"
                        showRequiredFlag={true}
                        formikProps={formikProps}
                        disabled={this.state.disableInput}
                      >
                        <option value="" defaultValue="Please Choose">
                          {this.props.intl.formatMessage({
                            id: "appointmentCreate.pleaseChoose",
                            defaultMessage: "Please Choose",
                          })}
                        </option>
                        {this.state.bookingAppointmentFor &&
                          this.state.bookingAppointmentFor.map(
                            ({ name, _id }, i) => (
                              <option value={_id} key={i}>
                                {isLtr() ? name?.value_en : name?.value_ar}
                              </option>
                            )
                          )}
                      </FieldRow>
                    </div>
                  )}
                </CheckAccess>

                <FieldRow
                  name="language"
                  type="select"
                  defaultLabel="Select Language"
                  labelId="appointmentCreate.language"
                  showRequiredFlag={true}
                  className="input_two form-control form-select mt-4"
                  formikProps={formikProps}
                  onChange={(event) => {
                    setFieldValue("language", event.target.value);
                  }}
                  data={this.languages}
                />

                {this.state?.data?.isPaid && (
                  <div className="row">
                    <div className="col-md-2 col-sm-2 col-xs-12">
                      <label className="form-label caps block">
                        <FormattedMessage
                          id="appointment.paymentStatus"
                          defaultMessage="Payment Status"
                        />
                      </label>
                    </div>
                    <div className="col-md-8 col-sm-8 col-xs-12">
                      <FormattedMessage
                        id="appointment.paid"
                        defaultMessage="Paid"
                      />
                    </div>
                  </div>
                )}

                <div className="offeredServices inSchedule">
                  {appState.state.token?.role === "sadmin" && (
                    <CheckAccess activityId={ActivitiesEnum.fast_drop_button}>
                      {data?.expressDocuments?.length ? (
                        <>
                          <hr className="seperator" />
                          <div className="express-docs-wrapper">
                            <h5>
                              <FormattedMessage
                                id="appointmentCreate.expressDocs"
                                defaultMessage="Express (FAST DROP)"
                              />
                            </h5>
                            <div className="express-comment-wrapper no-styles">
                              <div>
                                <Button
                                  onClick={() =>
                                    this.setState({ showCommentsList: true })
                                  }
                                  className={`pull-${
                                    appState.state.language === "en"
                                      ? "right"
                                      : "left"
                                  }`}
                                  style={{
                                    cursor: "pointer",
                                    margin: "0 15px",
                                  }}
                                >
                                  <span
                                    style={{ fontSize: "2rem" }}
                                    className="fa fa-comment fa-2"
                                  />
                                </Button>
                                {!data?.isExpressAppointmentConfirmed && (
                                  <Button
                                    variant="link"
                                    className={`pull-${
                                      appState.state.language === "en"
                                        ? "right"
                                        : "left"
                                    }`}
                                    onClick={() =>
                                      this.setState({ addComment: true })
                                    }
                                  >
                                    <FormattedMessage
                                      id="shop.addComment"
                                      defaultMessage="Add a comment"
                                    />
                                  </Button>
                                )}
                              </div>
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={this.confirmedExpressAppointment.bind(
                                  this
                                )}
                                disabled={data?.isExpressAppointmentConfirmed}
                              >
                                <FormattedMessage
                                  id="button.confirmExpressAppointment"
                                  defaultMessage="Confirm Express Appointment"
                                />
                              </button>
                            </div>
                          </div>

                          <div className="express-doc-imgs" role="group">
                            {data?.expressDocuments?.map((doc, i) => (
                              <div key={i}>
                                <p>
                                  <FormattedMessage
                                    id="appointmentCreate.title"
                                    defaultMessage={
                                      doc?.name?.["value_" + lang]
                                    }
                                  />
                                </p>
                                {this.isExtensionPDF(doc?.image) ? (
                                  <div className="file-name-display">
                                    <span>{doc?.image.split("75973")[1]}</span>
                                    <i
                                      onClick={() => this.viewFile(doc?.image)}
                                      className="fa fa-eye"
                                    />
                                  </div>
                                ) : (
                                  <ImageProvider
                                    imageSrc={doc.image}
                                    style={{ width: "200px" }}
                                    onClick={() => {
                                      this.setState({ lighboxSrc: doc.image });
                                    }}
                                  />
                                )}
                              </div>
                            ))}
                          </div>
                        </>
                      ) : null}

                      {data?.expressCarImages?.length ? (
                        <>
                          <div className="express-imgs">
                            <h5>
                              <FormattedMessage
                                id="appointmentCreate.expressImgs"
                                defaultMessage="Car Images"
                              />
                            </h5>
                          </div>
                          <div className="express-doc-imgs" role="group">
                            {data?.expressCarImages?.map((doc, i) => (
                              <div key={i}>
                                <p>
                                  <FormattedMessage
                                    id="appointmentCreate.title"
                                    defaultMessage={doc?.name}
                                  />
                                </p>
                                <ImageProvider
                                  imageSrc={doc.image}
                                  style={{ width: "200px" }}
                                  onClick={() => {
                                    this.setState({ lighboxSrc: doc.image });
                                  }}
                                />
                              </div>
                            ))}
                          </div>
                        </>
                      ) : null}
                    </CheckAccess>
                  )}
                </div>
              </div>
              <div className="dashboard-row -nobottom d-flex justify-content-between">
                <CheckAccess
                  activityId={ActivitiesEnum.cancel_appointment_button}
                >
                  {this.props.match.params.id &&
                  !data?.isOfferCompleted &&
                  !data?.isPaid ? (
                    <input
                      type="button"
                      className="btn btn-danger"
                      value={this.props.intl.formatMessage({
                        id: "global.cancelAppointment",
                        defaultMessage: "Cancel Appointment",
                      })}
                      onClick={this.cancelAppointment.bind(this)}
                      disabled={isSubmitting}
                    />
                  ) : (
                    <span></span>
                  )}
                </CheckAccess>

                <div>
                  {this.props.location.state?.state?.fromDashboard ? (
                    <CheckAccess
                      activityId={ActivitiesEnum.dashboard_update_appointment}
                    >
                      {this.props.authContext.authorized &&
                        !data?.isOfferCompleted &&
                        !data?.isPaid && (
                          <input
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginBottom: "0" }}
                            value={this.props.intl.formatMessage({
                              id: "global.submit",
                              defaultMessage: "Submit",
                            })}
                            onClick={() => this.checkValidations()}
                            disabled={isSubmitting}
                          />
                        )}
                    </CheckAccess>
                  ) : (
                    <CheckAccess
                      activityId={ActivitiesEnum.schedule_appointment_update}
                    >
                      {this.props.authContext.authorized &&
                        !data?.isOfferCompleted && (
                          <input
                            type="submit"
                            className="btn btn-primary"
                            style={{ marginBottom: "0" }}
                            value={this.props.intl.formatMessage({
                              id:
                                this.state.isServiceAdvisor &&
                                this.state.isPaid &&
                                !this.state?.data?.isPaid
                                  ? "global.payWithTamara"
                                  : "global.submit",
                              defaultMessage:
                                this.state.isServiceAdvisor &&
                                this.state.isPaid &&
                                !this.state?.data?.isPaid
                                  ? "Pay With Tamara"
                                  : "Submit",
                            })}
                            onClick={() => this.checkValidations()}
                            disabled={isSubmitting}
                          />
                        )}
                    </CheckAccess>
                  )}
                  <Button
                    variant="link"
                    onClick={() => {
                      this.state.paramsId
                        ? this.props.navigate("/dashboard")
                        : this.props.navigate("/appointments", {
                            fromCancelledAppointment: true,
                          });
                    }}
                    disabled={isSubmitting}
                  >
                    {this.props.intl.formatMessage({
                      id: "global.cancel",
                      defaultMessage: "Cancel",
                    })}
                  </Button>
                </div>
              </div>
            </Form>
          </div>

          {this.state.lighboxSrc && (
            <Lightbox
              mainSrc={`/api/util/image?key=${this.state.lighboxSrc}`}
              onCloseRequest={() => this.setState({ lighboxSrc: null })}
            />
          )}

          {/* <TamaraWidget
            type="installmentPlan"
            parentClass="checkout-tag"
            price={500}
          /> */}
        </div>
      </Layout>
    );
  }

  render() {
    const { intl } = this.props;
    const data = this.state.data;

    return (
      <Auth requireAuth={true} roles={["sadmin", "wsadmin", "spadmin"]}>
        <HeaderTitle
          id="appointment.scheduleAppointments"
          defaultMessage="Scheduled Appointments"
        />
        <Formik
          innerRef={(f) => {
            this.formik = f;
          }}
          enableReinitialize
          initialValues={{
            mrMs: data?.title || "Mr",
            firstName: data?.firstName || "",
            lastName: data?.lastName || "",
            emailAddress: data?.email || "",
            phoneNumber: data?.phoneNumber || "",
            brand: data?.carId?.brand?.value_en || "",
            model: data?.carId?.product?.value_en || "",
            otherBrand: data?.otherBrand || "",
            otherModel: data?.otherModel || "",
            carNickName: data?.carNickName || "",
            year: data?.carId?.modelYear,
            plateNumber: data?.carNumberPlate?.split("-")[0],
            plateChar: data?.carNumberPlate?.split("-")[1],
            city: data?.city,
            source: data?.source,
            otherSource: data?.source,
            providers: data?.branchId?._id,
            image0: "",
            image1: "",
            image2: "",
            image3: "",
            image4: "",
            imageNotes: data?.imageNote,
            appointmentHash: data?.appointmentHash,
            language: data?.language || "en",
            // carOwnBy:
            //   data?.requiredDocuments?.carOwnBy ||
            //   this?.state?.carOwnBy[0]?._id,
            bookingAppointmentFor:
              "" || data?.requiredDocuments?.bookingAppointmentFor,
            createdBy:
              data?.createdBy?.isSubAdmin || data?.isCreatedFromCms
                ? `${data?.createdBy?.fullName || ""} on CMS`
                : `${(
                    (data?.createdBy?.firstName || "") +
                    " " +
                    (data?.createdBy?.lastName || "")
                  ).trim()} on WEB`,
            collectionAddress:
              data?.collectionAndDelivery?.collectionAddress || "",
            deliveryAddress: data?.collectionAndDelivery?.deliveryAddress || "",
            needCareemPromo: data?.needCareemPromo || false,
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string()
              .label(
                intl.formatMessage({
                  id: "appointmentCreate.firstName",
                  defaultMessage: "First name",
                })
              )
              .trim()
              .required(),
            lastName: Yup.string()
              .label(
                intl.formatMessage({
                  id: "appointmentCreate.lastName",
                  defaultMessage: "Last name",
                })
              )
              .required(),
            language: Yup.string()
              .label(
                intl.formatMessage({
                  id: "appointmentCreate.language",
                  defaultMessage: "Language",
                })
              )
              .required(),
            phoneNumber: Yup.string()
              .label(
                intl.formatMessage({
                  id: "appointmentCreate.phone",
                  defaultMessage: "Phone Number",
                })
              )
              .matches(
                /^[0-9+]*$/,
                <FormattedMessage
                  id="appointmentCreate.onlyNumber"
                  defaultMessage="Phone no must be only numbers"
                />
              )
              .min(
                13,
                intl.formatMessage({
                  id: "appointmentCreate.min13Num",
                  defaultMessage: "Must be minimum 13 numbers",
                })
              )
              .max(
                14,
                intl.formatMessage({
                  id: "appointmentCreate.max14Num",
                  defaultMessage: "Must be maximum 14 numbers",
                })
              )

              .required(),
            emailAddress: Yup.string()
              .email(
                intl.formatMessage({
                  id: "appointmentCreate.validEmail",
                  defaultMessage: "Email address must be in a valid format",
                })
              )
              .label(
                intl.formatMessage({
                  id: "appointmentCreate.phone",
                  defaultMessage: "Email address",
                })
              ),
            brand: Yup.string()
              .label(
                intl.formatMessage({
                  id: "appointmentCreate.brand",
                  defaultMessage: "Car Brand",
                })
              )
              .required(),
            model: Yup.string()
              .label(
                intl.formatMessage({
                  id: "appointmentCreate.model",
                  defaultMessage: "Car Model",
                })
              )
              .required(),
            otherBrand: Yup.string()
              .label(
                intl.formatMessage({
                  id: "appointmentCreate.brand",
                  defaultMessage: "Car Brand",
                })
              )
              .when("brand", {
                is: (val) => val === "OTHER",
                then: (schema) => schema.required(),
              }),
            otherModel: Yup.string()
              .label(
                intl.formatMessage({
                  id: "appointmentCreate.model",
                  defaultMessage: "Car Model",
                })
              )
              .when("model", {
                is: (val) => val === "OTHER",
                then: (schema) => schema.required(),
              }),
            year: Yup.string()
              .label(
                intl.formatMessage({
                  id: "appointmentCreate.year",
                  defaultMessage: "Car Year",
                })
              )
              .required(),
            plateNumber: Yup.string()
              .label(
                intl.formatMessage({
                  id: "appointmentCreate.plateNumber",
                  defaultMessage: "Car Plate Number",
                })
              )
              .min(
                4,
                intl.formatMessage({
                  id: "appointmentCreate.min4Length",
                  defaultMessage: "Must be miniumum 4 digits",
                })
              )
              .required(),
            plateChar: Yup.string()
              .label(
                intl.formatMessage({
                  id: "appointmentCreate.plateChar",
                  defaultMessage: "Car Plate Number",
                })
              )
              .min(
                5,
                intl.formatMessage({
                  id: "appointmentCreate.min3Length",
                  defaultMessage: "Must be miniumum 3 letters",
                })
              )
              .required(),
            city: Yup.string()
              .label(
                intl.formatMessage({
                  id: "appointmentCreate.city",
                  defaultMessage: "City",
                })
              )
              .required(),
            providers: Yup.string()
              .label(
                intl.formatMessage({
                  id: "appointmentCreate.branch",
                  defaultMessage: "Branch",
                })
              )
              .required(),
            // carOwnBy: Yup.string()
            //   .label(
            //     intl.formatMessage({
            //       id: "appointmentCreate.carOwnBy",
            //       defaultMessage: "Car Own By",
            //     })
            //   )
            //   .required(),
            bookingAppointmentFor: Yup.string()
              .label(
                intl.formatMessage({
                  id: "appointmentCreate.bookingAppointmentFor",
                  defaultMessage: "Booking Appointment For",
                })
              )
              .required(),
          })}
          onSubmit={this.handleSubmit.bind(this)}
        >
          {this._renderBody.bind(this)}
        </Formik>
      </Auth>
    );
  }
}

export default withAuthContext(withRouter(injectIntl(AppointmentForm)));
