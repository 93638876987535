import React, { useState } from "react";
import UploadThumb from "./UploadThumb";
import FileInput from "./FileInput";
import { Field } from "formik";
import { FormattedMessage, injectIntl } from "react-intl";
import classNames from "classnames";
import appState from "../../state/AppStateContainer";
import _ from "lodash";
import AutoSuggestInput from "./AutoSuggestInput";
import HTMLInput from "./HTMLInput";
import Tag from "./Tag";
import datePicker from "./DatePicker";
import DatePickerSingle from "./DatePickerSingle";
import { withAuthContext } from "../../state/AuthContext";
import VideoInput from "./VideoInput";
import UploadVideoThumb from "./UploadVideoThumb";

const FieldRow = injectIntl(
  ({
    classRow = "",
    isRow = false,
    colSize = 2,
    image = undefined,
    fileicon,
    showVideoThumbnail,
    cameraIcon,
    videoIcon,
    name,
    type,
    children,
    thumbnail_selected = false,
    thumbnail_unselected = false,
    showThumbnail,
    showRequiredFlag,
    labelId,
    defaultLabel,
    placeholderId,
    defaultPlaceholder,
    intl,
    disabled,
    data,
    fieldStyle,
    inputSize = 8,
    getLabelValue,
    getSuggestions,
    formikProps,
    initialStartDate,
    initialEndDate,
    startDatePlaceholderText,
    endDatePlaceholderText,
    legalDocumentation = undefined,
    inputClassName = undefined,
    spRegistrationLogo,
    crop,
    aspectRatio,
    multiple,
    allowFiles,
    showFocusBorder = undefined,
    isPdf = undefined,
    imageSize = "Default",
    forCarousel,
    rows,
    onChange,
    onInput,
    customPhone,
    defaultValue,
    forServices,
    initialStartFrom,
    initialExpirydate,
    disabledFile = false,
    authorizedFrom = undefined,
    textEditor,
    showPasswordEyeIcon = false,
    allowDecimal = false,
  }) => {
    const { errors, touched, values } = formikProps;
    disabled = disabled || authorizedFrom ? disabled : false;

    const [inputType, setInputType] = useState(type || "text");

    const config = {};
    let FieldComponent = Field;

    if (onChange) {
      config.onChange = onChange;
    }
    if (onInput) {
      config.onInput = onInput;
    }

    if (type === "textarea") {
      config.component = type;
      config.cols = "30";
      config.rows = rows ? rows : "3";
    } else if (type === "number") {
      if (allowDecimal) {
        config.step = "0.01"; // This allows values with two decimal places
      }
      config.min = 0;
    } else if (type === "select") {
      config.component = type;
      if (defaultValue) {
        config.defaultValue = defaultValue;
      }
    } else if (type === "file") {
      FieldComponent = FileInput;
    } else if (type === "video") {
      FieldComponent = VideoInput;
    }
    // else if (type === "color") {
    //   FieldComponent = ColorPicker;
    // }
    else if (type === "suggest") {
      FieldComponent = AutoSuggestInput;
    } else if (type === "html") {
      FieldComponent = HTMLInput;
    } else if (type === "date") {
      FieldComponent = datePicker;
    } else if (type === "tag") {
      FieldComponent = Tag;
    } else if (type === "singleDate") {
      FieldComponent = DatePickerSingle;
    } else if (type === "label") {
      FieldComponent = ({ fieldStyle }) => {
        let value = getLabelValue ? getLabelValue() : null;
        if (!_.isNull(value) || getLabelValue) {
          value = intl.formatMessage({
            id: placeholderId,
            defaultMessage: value || defaultPlaceholder,
          });
        } else if (!_.isNull(value)) {
          value = intl.formatMessage({
            id: placeholderId,
            defaultMessage: defaultPlaceholder,
          });
        }

        return (
          <span disabled className="form-control disabled" style={fieldStyle}>
            {value}
          </span>
        );
      };
    } else if (type === "checkbox") {
      config.checked = values[name];
      // config.type = type;
    } else {
      // config.type = type || "text";
      if (customPhone) config.value = "+966" + values.phoneNumber.slice(4);
    }

    if (disabled) {
      config.disabled = disabled;
    }
    return (
      <div
        className={
          multiple
            ? undefined
            : isRow === false
            ? `row ${classRow} `
            : "css-10nd86i col-md-10 col-xs-12"
        }
      >
        {!_.isUndefined(defaultLabel) && (
          <div className={`col-md-${colSize} col-sm-${colSize} col-xs-12`}>
            <label
              className={classNames("form-label caps block", {
                "require-flag": showRequiredFlag,
              })}
              htmlFor={name}
            >
              <FormattedMessage id={labelId} defaultMessage={defaultLabel} />
            </label>
          </div>
        )}

        <div
          className={
            type === "checkbox"
              ? "col-checkbox-input"
              : multiple
              ? undefined
              : isRow === false
              ? `${
                  !textEditor ? "input-group" : "text-editor-quill"
                } col-md-${inputSize} col-sm-${inputSize} col-xs-12`
              : "input-group"
          }
        >
          <FieldComponent
            type={inputType}
            fileicon={fileicon}
            videoicon={videoIcon}
            cameraicon={cameraIcon}
            image={image}
            ispdf={isPdf}
            id={name}
            name={name}
            style={fieldStyle}
            formikprops={formikProps}
            {...config}
            className={
              type === "checkbox" ? "" : `form-control ${inputClassName}`
            }
            placeholder={
              placeholderId &&
              intl.formatMessage({
                id: placeholderId,
                defaultMessage: defaultPlaceholder,
              })
            }
            children={
              children ||
              (data &&
                data.length > 0 &&
                data.map((o) => {
                  let name;
                  if (o.name) name = o.name;
                  else if (o.promoCode) name = o.promoCode;
                  else if (o.color) name = o.color;
                  else
                    name =
                      appState.state.language === "ar"
                        ? o.arabicName || o.englishName
                        : o.englishName;

                  return (
                    <option key={"cat-" + o.id || o._id} value={o.id || o._id}>
                      {name}
                    </option>
                  );
                }))
            }
            allowfiles={allowFiles}
            spregistrationlogo={spRegistrationLogo}
            legaldocumentation={legalDocumentation}
            getsuggestions={getSuggestions}
            initialstartdate={initialStartDate}
            initialenddate={initialEndDate}
            startdateplaceholdertext={startDatePlaceholderText}
            enddateplaceholdertext={endDatePlaceholderText}
            crop={crop}
            aspectratio={aspectRatio}
            showfocusborder={showFocusBorder}
            forcarousel={forCarousel}
            forservices={forServices}
            initialStartFrom={initialStartFrom}
            initialExpirydate={initialExpirydate}
            disabledfile={disabledFile}
          />
          {showVideoThumbnail && type === "video" && (
            <UploadVideoThumb
              file={values[name]}
              showfocusborder={showFocusBorder}
            />
          )}

          {showThumbnail &&
            type === "file" &&
            !thumbnail_selected &&
            !thumbnail_unselected && (
              <UploadThumb
                file={values[name]}
                showFocusBorder={showFocusBorder}
              />
            )}
          {showThumbnail && thumbnail_selected && (
            <UploadThumb file={values.thumbnail_selected} />
          )}
          {showThumbnail && thumbnail_unselected && (
            <UploadThumb file={values.thumbnail_unselected} />
          )}
          {touched[name] && errors[name] && (
            <span className="text-warning">{errors[name]}</span>
          )}
          {type == "file" && image && (
            <span className="text-indicator">{imageSize + " Pixels"}</span>
          )}
          {type === "password" && showPasswordEyeIcon && (
            <span
              className="password-inp-icon"
              onClick={() => setInputType(inputType === type ? "text" : type)}
            >
              <i
                className={`fa ${
                  inputType === type ? "fa-eye" : "fa-eye-slash"
                }`}
              />
            </span>
          )}
        </div>
      </div>
    );
  }
);

export default withAuthContext(FieldRow);
