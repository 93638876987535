import React, { Component } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import _ from "lodash";
import Auth from "../shared/Auth";
import Layout from "../shared/Layout";
import withRouter from "../../util/withRouter";
import appState from "../../state/AppStateContainer";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import { Modal, Button } from "react-bootstrap";
import BranchesService from "../../services/branchesService";
import appointmentService from "../../services/appointmentService";
import { getFormatedDate, halfHourlySlots } from "../../util/utils";
import moment from "moment";
import { ActivitiesEnum } from "../../staticData/Activities";
import CheckAccess from "../shared/CheckAccess";

export class AllAppointments extends Component {
  constructor() {
    super();
  }

  state = {
    events: [],
    eventDetails: null,
    branches: [],
    appointmentDetails: {
      branchId: "",
      startDate: "",
      endDate: "",
      appointmentHash: "",
    },
    appointmentId: "",
    loading: false,
  };

  componentDidMount() {
    this.fetchBranches();
  }

  fetchBranches = async () => {
    this.setState({ loading: true });
    try {
      let branches = await BranchesService.getActiveBranches();
      branches = branches?.map((b) => ({
        label: { en: b.name?.value_en, ar: b.name?.value_ar },
        value: b._id,
      }));
      this.setState({ branches });

      if (branches && branches.length === 1) {
        this.setState(
          {
            appointmentDetails: {
              ...this.state.appointmentDetails,
              branchId: branches[0].value,
            },
          },
          () => {
            // Fetch appointments after setting the default branchId
            this.fetchAppointments();
          }
        );
      }
    } catch (e) {
      console.log(e, "error");
      this.props.navigate("/error", {
        error: _.get(e, "response.data.error", "error.unexpectedError"),
      });
    }
    this.setState({ loading: false });
  };

  fetchOccasions = async ({ branchId, startDate, endDate }) => {
    const response = await BranchesService.getOcassions(
      branchId,
      startDate,
      endDate
    );
    return Array.isArray(response) ? response : [];
  };

  fetchAppointments = async (options) => {
    this.setState({ loading: true });

    const { appointmentDetails } = this.state;
    const _options = options || appointmentDetails;
    let response = await appointmentService.getMonthlyAppointments(_options);
    const occasions = await this.fetchOccasions(_options);

    let events = [];
    if (response?.length) {
      response.sort(
        (a, b) =>
          halfHourlySlots.indexOf(a.timeSlot) - halfHourlySlots.indexOf(b.timeSlot)
      );
      events = response.map((a) => ({
        title: `${a.timeSlot} (${a.appointment_details.length})`,
        date: a.date,
        appointmentDetails: a.appointment_details,
        eventTitle: `${a.date} (${a.timeSlot})`,
      }));
    }

    this.setState({ events: [...events, ...occasions], loading: false });
  };

  handleEventClick = (e) => {
    e.jsEvent.preventDefault();
    if (e.event.extendedProps && e.event.extendedProps.appointmentDetails) {
      this.setState({
        eventDetails: {
          bookings: e.event.extendedProps.appointmentDetails,
          title: e.event.extendedProps.eventTitle,
        },
      });
    }
  };

  handleModalClose() {
    this.setState({ eventDetails: null });
  }

  handleChange = (e) => {
    let data = {
      ...this.state.appointmentDetails,
      [e.target.name]: e.target.value,
    };
    this.setState({ appointmentDetails: data });
    this.fetchAppointments(data);
  };

  handleUpdate = (appointmentId) => {
    this.setState({ appointmentId }, () => {
      this.props.navigate(`/appointment/${appointmentId}`, {
        state: {
          fromDashboard: true,
        },
      });
    });
  };

  renderEventContent = (info) => {
    info.el.setAttribute("title", info.event.title);
  };

  render() {
    const lang = appState.state.language;
    const { intl } = this.props;
    const { branches, events, appointmentDetails, eventDetails } = this.state;
    const { branchId, startDate, endDate } = appointmentDetails;
    let count = 0;
    events.forEach((e) => (count += e.appointmentDetails?.length || 0));
    let dayNames =
      appState.state.language === "en"
        ? [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ]
        : [
            "الأحد",
            "الاثنين",
            "الثلاثاء",
            "الأربعاء",
            "الخميس",
            "جمعة",
            "السبت",
          ];

    return (
      <Auth requireAuth={true} roles={["sadmin"]}>
        <Layout
          hidden={this.props.match.params.isFormOpen}
          loading={this.state.loading}
        >
          <div className="capcities-calendar">
            <div className="select-branch">
              <label>
                <FormattedMessage
                  id="appointment.branches"
                  defaultMessage="Branches"
                />
                <select
                  name="branchId"
                  className="form-control"
                  onChange={this.handleChange}
                  value={branchId}
                >
                  <option key="no-val" value="" disabled>
                    {intl.formatMessage({
                      id: "appointment.selectBranch",
                      defaultMessage: "Select Branch",
                    })}
                  </option>
                  {branches?.map((b) => (
                    <option key={b.value} value={b.value}>
                      {b.label[lang]}
                    </option>
                  ))}
                </select>
              </label>
            </div>

            {branchId ? (
              <>
                <div className="num-of-appointments">
                  <h6>
                    {count + " "}
                    <FormattedMessage
                      id="appointment.numberOfAppointments"
                      defaultMessage="appointments are scheduled between"
                    />
                    {" (" + getFormatedDate(startDate, "MMM DD, YYYY") + " - "}
                    {getFormatedDate(
                      moment(endDate).subtract(1, "days"),
                      "MMM DD, YYYY"
                    ) + ")"}
                  </h6>
                </div>

                <div className="lane-capacities">
                  <FullCalendar
                    defaultView="dayGridMonth"
                    plugins={[dayGridPlugin, interactionPlugin]}
                    events={events}
                    eventClick={this.handleEventClick}
                    datesSet={(date) => {
                      let data = {
                        ...this.state.appointmentDetails,
                        startDate: date.start,
                        endDate: date.end,
                      };
                      this.setState({ appointmentDetails: data });
                      if (branchId) this.fetchAppointments(data);
                    }}
                    eventDidMount={this.renderEventContent}
                    dayHeaderContent={(arg) => dayNames[arg.date.getDay()]}
                    buttonText={{ today: lang === "en" ? "Today" : "اليوم" }}
                    direction={lang === "en" ? "ltr" : "rtl"}
                    locale={lang}
                    eventOrder={halfHourlySlots}
                    // hiddenDays={[2, 4]}
                  />
                </div>
              </>
            ) : (
              <h3>
                <FormattedMessage
                  id="appointment.seeAppointments"
                  defaultMessage="Select Branch to view Appointments"
                />
              </h3>
            )}
          </div>
        </Layout>

        <Modal
          className="lc-details-modal"
          show={eventDetails}
          onHide={() => this.handleModalClose()}
          centered={true}
        >
          <Modal.Header>
            <Modal.Title>
              <FormattedMessage
                id="appointment.appointmentsOn"
                defaultMessage="Appointments on "
              />
              <span>{eventDetails?.title}</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {eventDetails?.bookings?.map((e) => (
              <div className="appt" key={e.appointmentId}>
                <h4>{e.username}</h4>
                {e.appointmentHash ? (
                  <p>
                    <FormattedMessage
                      id="appointment.appointmentReference"
                      defaultMessage="Appointment Reference No: "
                    />
                    {e.appointmentHash}
                  </p>
                ) : null}
                <p>
                  <FormattedMessage
                    id="appointment.booked"
                    defaultMessage="Booked Services:"
                  />
                  {e.serviceName["value_" + lang]}
                </p>
                <p>
                  <FormattedMessage
                    id="appointment.CreatedBy-Platform"
                    defaultMessage="Created By / Platform: "
                  />
                  {e.createdBy}
                </p>

                {appState.state.token?.role === "sadmin" && (
                  <CheckAccess
                    activityId={ActivitiesEnum.dashboard_update_appointment}
                  >
                    <button
                      className="btn-update"
                      onClick={() => this.handleUpdate(e.appointmentId)}
                    >
                      <FormattedMessage
                        id="appointment.update"
                        defaultMessage="Update"
                      />
                    </button>
                  </CheckAccess>
                )}
                {appState.state.token?.role === "sadmin" && (
                  <CheckAccess
                    activityId={
                      ActivitiesEnum.dashboard_see_more_appointment_details
                    }
                  >
                    <button
                      className="btn-update"
                      onClick={() => this.handleUpdate(e.appointmentId)}
                    >
                      <FormattedMessage
                        id="appointment.seeMore"
                        defaultMessage="See more"
                      />
                    </button>
                  </CheckAccess>
                )}
              </div>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => this.handleModalClose()}>
              <FormattedMessage id="appointment.close" defaultMessage="Close" />
            </Button>
          </Modal.Footer>
        </Modal>
      </Auth>
    );
  }
}

export default withRouter(injectIntl(AllAppointments));
