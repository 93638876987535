import BaseService from "../../../services/baseService";
import BranchesService from "../../../services/branchesService";
import { halfHourlySlots } from "../../../util/utils";

class InitForm extends BaseService {
  constructor() {
    super();
    this.defaultSelect = { id: "workingDays", name: "Working Days" };
  }

  customizeHourlySlots() {
    let shopTimings = halfHourlySlots.map((time) => {
      const hour = time.slice(0, time.indexOf(":"));
      let displayTime = hour.length == 1 ? "0" + time : time;
      displayTime = displayTime.replace("am", " am").replace("pm", " pm");

      return {
        id: time,
        name: displayTime,
      };
    });

    return shopTimings;
  }

  async getBranch(id) {
    // Getting branch by ID
    const branch = await BranchesService.getBranch(id);
    const serviceIds = await this.getOfferedServices(id, branch);

    return { branch, serviceIds };
  }

  async getOfferedServices(id, branch) {
    // Creating array of serviceIDs of all offered services
    let serviceIds = [];
    branch.offeredServices.forEach((service) => {
      serviceIds.push(service._id);
    });

    return serviceIds;
  }

  // Getting initial data during update branch
  async getSlots(id, serviceIds) {
    let slotDay;
    let slotsData = [];
    let personalizedData = [];
    let personalizedEventsData = [];
    let workingDaysSlots = [];
    let eventDaysSlots = [];

    // Creating data structure for local states to show all selected timeslots on modal
    const getAllServices = await BranchesService.getSlotsByService(id, serviceIds);
    getAllServices.forEach((service) => {
      service.data.forEach((lc) => {
        lc.timeSlots.laneCapacity[0].slots.forEach(({ time, numberOfSlots, specialSlots }) => {
          slotDay = lc.timeSlots.day;
          slotsData.push({ time, numberOfSlots });

          // Collecting slots for event days here
          specialSlots.forEach(({ ocassionId, numberOfSlots }) => {
            const hasEvent = personalizedEventsData.findIndex(({ event }) => event === ocassionId);
            if (hasEvent > -1) {
              const hasDay = personalizedEventsData[hasEvent].slots.findIndex(({ day }) => day === slotDay);
              if (hasDay > -1) {
                personalizedEventsData[hasEvent].slots[hasDay].timeSlots.push({ ocassionId, time, numberOfSlots });
              } else {
                personalizedEventsData[hasEvent].slots.push({
                  day: slotDay, timeSlots: [
                    { ocassionId, time, numberOfSlots }
                  ]
                })
              }
            } else {
              personalizedEventsData.push({
                event: ocassionId, slots: [
                  {
                    day: slotDay, timeSlots: [
                      { ocassionId, time, numberOfSlots, slotDay }
                    ]
                  }
                ]
              });
            }
          });
        });

        personalizedData.push({ day: slotDay, timeSlots: slotsData });
        slotsData = [];
      });

      workingDaysSlots.push({ service: service._id, slots: personalizedData });
      eventDaysSlots.push({ service: service._id, events: personalizedEventsData });

      personalizedData = [];
      personalizedEventsData = [];
    });

    return { workingDaysSlots, eventDaysSlots };
  }

  // Get event and ocassions
  async deCompileOccasions(id) {
    const getOccasions = await BranchesService.getOcassions(id);
    if (!getOccasions) return
    let occasions = getOccasions.map(({ _id, title, dateRange, shopTimings, isHoliday, isActive }) => {
      return {
        id: _id,
        name: title,
        startDate: dateRange.from.slice(0, 10),
        endDate: dateRange.to.slice(0, 10),
        openTime: shopTimings?.openingTime,
        closeTime: shopTimings?.closingTime,
        isHoliday: isHoliday,
        isActive: isActive
      };
    });

    occasions.unshift(this.defaultSelect);
    let events = occasions.slice(1);

    return { occasions, events }
  }

  // Getting initial services
  deCompileServices(branch) {
    // Getting offered services to show selected
    let servicesData = [];
    let selectedIds = [];
    branch.offeredServices.forEach((s) => {
      if (s.isActive) {
        servicesData.push({ id: s._id, en: s.name.value_en, ar: s.name.value_ar });
        selectedIds.push(s._id);
      }
    });

    return { servicesData, selectedIds }
  }
}

export default new InitForm();