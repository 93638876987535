import axios from "axios";
import appState from "../state/AppStateContainer";
import FileDownload from "js-file-download";
import _ from "lodash";
import moment from "moment";
import BaseService from "./baseService";
import { blobToFile } from "../util/utils";
import "floatthead";

class Services extends BaseService {
  constructor() {
    super();
  }

  /**
   * login
   * @description login User
   * @static
   * @param {String} username - username
   * @param {String} password - Goal Description
   * @returns {Object.<User>}
   * @memberOf Service
   */
  async login(
    username,
    password,
    isSimpleMode,
    currentLongitude,
    currentLatitude
  ) {
    const result = await axios.post("/api/auth/login", {
      username,
      password,
      isSimpleMode,
      currentLongitude,
      currentLatitude,
    });
    return result.data;
  }

  async verificationOtp(values) {
    const result = await axios.post("/api/auth/verification-otp", values);
    return result.data?.response;
  }

  async verifyPassword(values) {
    const result = await axios.post("/api/password-verification", values);
    return result && result.data;
  }

  /**
   * login
   * @description login User
   * @static
   * @param {String} id - username
   * @returns {Object.<User>}
   * @memberOf Service
   */
  async loginAs(id) {
    const response = await axios.get("/api/auth/loginAs", {
      params: { id },
    });
    return response.data;
  }

  async ForgotPassword(username) {
    const result = await axios.post("/api/forgot/password", {
      username,
    });
    return result.data;
  }

  async resetPasswordByAdmin({ code, newPassword, confirmPassword }) {
    const result = await axios.put("/api/reset/password", {
      code,
      newPassword,
      confirmPassword,
    });
    return result.data;
  }
  async setAdminPassword({ code, newPassword, confirmPassword }) {
    const response = await axios.put("/api/admin/password", {
      code,
      newPassword,
      confirmPassword,
    });
    return response.data;
  }

  async checkUsername(username) {
    const response = await axios.get("/api/username/exist", {
      params: { username },
    });
    return response.data;
  }

  async userEmailActivate(activation_token) {
    const response = await axios.get("/api/user/email/activate", {
      params: { activation_token },
    });
    return response.data;
  }

  /**
   * resendActivationEmail
   * @description login User
   * @static
   * @param {Number} id
   * @memberOf Service
   */
  async shopResendActivationEmail(id) {
    console.log("resendActivationEmail");
    const response = await axios.get("/api/shop/resendActivationEmail", {
      params: { id },
    });
    return response.data;
  }

  //----------------------autoHub service start----------------------------
  async getPages() {
    const result = await axios.get("/api/all-pages");
    return result?.data?.response;
  }

  async addAndUpdatepage(name, isActive) {
    const response = await axios.post("/api/page", { name, isActive });
    return response.data;
  }

  async getEstimationRequest(
    searchFilter,
    approvalFilter,
    statusFilter,
    _pageIndex,
    pageSize,
    alphabeticalOrder,
    options = {}
  ) {
    const result = await axios.get("/api/estimation-request", {
      params: {
        searchFilter,
        approvalFilter,
        statusFilter,
        _pageIndex,
        pageSize,
        alphabeticalOrder,
        summary: options.summary,
        fromDate: options.fromDate,
        toDate: options.toDate,
      },
      progressBar: options.progressBar,
    });
    return result.data.response;
  }

  async getServicesName({ fromCategoryScreen }) {
    const result = await axios.get("/api/services-name", {
      params: {
        fromCategoryScreen,
      },
    });
    return result.data.response;
  }

  async getServicesNameCommonBranches({ selectedBranchIds }) {
    const result = await axios.post("/api/common-branch-services", {
      selectedBranchIds,
    });
    return result.data.response;
  }

  async getCommonServiceTypeCarBrandModels({ selectedServiceTypeIds }) {
    const result = await axios.post("/api/common-services-cars", {
      selectedServiceTypeIds,
    });
    return result.data.response;
  }

  async getServicesForOffers() {
    const result = await axios.get("/api/services-for-offers");
    return result.data.response;
  }

  async getContactus(
    searchFilter,
    approvalFilter,
    serviceFilter,
    pageIndex,
    pageSize,
    alphabeticalOrder,
    inquiry,
    options = {}
  ) {
    const params = {
      searchFilter,
      approvalFilter,
      serviceFilter,
      pageIndex,
      pageSize,
      alphabeticalOrder,
      summary: options.summary,
      fromDate: options.fromDate,
      toDate: options.toDate,
      inquiry: inquiry === "contacts" ? "" : inquiry,
    };

    const response = await axios.get("/api/contactus", {
      params,
      progressBar: options.progressBar,
    });
    return response.data;
  }

  async downloadContactList(
    serviceFilter,
    inquiry,
    language,
    fromDate,
    toDate
  ) {
    const xlsx = true;
    const response = await axios.get("/api/contactus", {
      params: {
        serviceFilter,
        language,
        fromDate,
        toDate,
        xlsx: xlsx,
        inquiry: inquiry === "contacts" ? "" : inquiry,
      },
      responseType: "blob",
    });
    console.log("resp", response.data);
    FileDownload(
      response.data,
      `contactus_${inquiry === "contacts" ? "" : inquiry}${moment().format(
        "DDMMYYYY"
      )}.xlsx`,
      "application/vnd.openxmlformats"
    );
  }

  async getUpcomingServices() {
    const response = await axios.get("/api/upcoming-services");
    return response.data;
  }

  async getContactusById(id, options = {}) {
    const response = await axios.get(`/api/contactus/${id}`, {
      progressBar: options.progressBar,
    });
    return response.data;
  }

  async updateContactusById(data) {
    const response = await axios.patch(`/api/contactus`, data);
    return response.data;
  }

  async addMedia(data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.post("/api/media", formData);
    return response.data;
  }

  async getMediaData(options = {}) {
    const response = await axios.get("/api/media", {
      progressBar: options.progressBar,
    });
    return response.data;
  }

  async addOffer(data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.post("/api/offer", formData);
    return response.data;
  }

  async getOffers({ searchFilter, summary }) {
    const response = await axios.get("/api/offers", {
      params: {
        searchFilter,
        summary,
      },
    });
    return response.data?.response;
  }

  async getOfferById(id, options = {}) {
    const result = await axios.get("/api/offer", {
      params: { id },
      progressBar: options.progressBar,
    });
    return result.data.response;
  }
  async updateOffersSorting(payload) {
    const response = await axios.put("/api/offers-sorting", {
      data: payload,
    });
    return response.data;
  }
  async updateOffer(data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.put("/api/offer", formData);
    return response.data;
  }

  async addProduct(data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const result = await axios.post("/api/product", formData);
    return result?.data?.response;
  }

  async getProduct(id, options = {}) {
    const result = await axios.get("/api/product/" + id, {
      progressBar: options.progressBar,
    });
    return result?.data?.response;
  }

  async updateProduct(data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const result = await axios.put("/api/product", formData);
    return result?.data?.response;
  }

  async getProducts(
    value,
    statusFilter,
    categoryFilter,
    pageIndex,
    pageSize,
    options = {},
    isActive = false
  ) {
    const result = await axios.get("/api/products", {
      params: {
        value,
        statusFilter,
        categoryFilter,
        pageIndex,
        pageSize,
        alphabeticalOrder: options?.alphabeticalOrder,
        summary: options?.summary,
        fromDate: options?.fromDate,
        toDate: options?.toDate,
        isActive,
      },
      progressBar: options?.progressBar,
    });

    return result?.data?.response;
  }

  async getOrders(
    searchFilter,
    statusFilter,
    branchFilter,
    orderTypeFilter,
    appointmentFilter,
    eShopFilter,
    branchIds,
    upsellFilter,
    refundFilter,
    collectionAndDeliveryFilter,
    satisfiedFilter,
    sourceFilter,
    paymentBrandFilter,
    pageIndex,
    pageSize,
    options = {},
    summary
  ) {
    const result = await axios.get("/api/orders", {
      params: {
        searchFilter,
        statusFilter,
        branchFilter,
        orderTypeFilter,
        appointmentFilter,
        eShopFilter,
        branchIds,
        upsellFilter,
        refundFilter,
        collectionAndDeliveryFilter,
        satisfiedFilter,
        sourceFilter,
        paymentBrandFilter,
        pageIndex,
        pageSize,
        alphabeticalOrder: options?.alphabeticalOrder,
        fromDate: options?.fromDate,
        toDate: options?.toDate,
        summary: summary,
      },
      progressBar: options?.progressBar,
    });

    return result?.data?.response;
  }

  async getOrdersReport(
    searchFilter,
    statusFilter,
    branchFilter,
    orderTypeFilter,
    appointmentFilter,
    eShopFilter,
    branchIds,
    upsellFilter,
    refundFilter,
    paymentBrandFilter,
    pageIndex,
    pageSize,
    options = {},
    summary
  ) {
    const result = await axios.get("/api/orders/report", {
      params: {
        searchFilter,
        statusFilter,
        branchFilter,
        orderTypeFilter,
        appointmentFilter,
        eShopFilter,
        branchIds,
        upsellFilter,
        refundFilter,
        paymentBrandFilter,
        pageIndex,
        pageSize,
        alphabeticalOrder: options?.alphabeticalOrder,
        summary: options?.summary,
        fromDate: options?.fromDate,
        toDate: options?.toDate,
        summary: summary,
      },
      progressBar: options?.progressBar,
    });

    return result?.data?.response;
  }

  async getOrder(id, options) {
    const result = await axios.get(`/api/order/${id}`, {
      progressBar: options?.progressBar,
    });

    return result?.data?.response;
  }
  async createOrder(orderPayload) {
    const result = await axios.post(`/api/order`, orderPayload);

    return result?.data?.response;
  }

  async updateOrderStatus(id) {
    const response = await axios.put(`/api/order/${id}`);
    return response.data;
  }

  async refundOrderWithReason(payload) {
    const response = await axios.put(`/api/refund`, payload);
    return response.data;
  }

  async sendSmsToUser({ id, phoneNumber, source }) {
    const result = await axios.post("/api/order/sms", {
      phoneNumber,
      id,
      source,
    });

    return result?.data?.response;
  }

  async verifyOtpForOrderCompletion({
    id,
    phoneNumber,
    code: otp,
    completionKey,
    upsellServiceAction,
    isCndOrder,
    source,
  }) {
    const result = await axios.post("/api/order/verify", {
      phoneNumber,
      id,
      otp,
      completionKey,
      upsellServiceAction,
      isCndOrder,
      source,
    });

    return result?.data?.response;
  }

  async sendCadNotificationToCustomer({ id, isCadDelivery }) {
    const result = await axios.put("/api/order/cad/notification", {
      id,
      isCadDelivery,
    });
    return result?.data?.response;
  }

  async changeCndOrderStatus({ id, status }) {
    const result = await axios.put("/api/order/cad/status", {
      id,
      status,
    });
    return result?.data?.response;
  }

  async refundOrder(id, type) {
    const response = await axios.get(`/api/order/${id}/refund/${type}`, {
      responseType: "blob",
    });

    return response.data;
  }

  async getCategoriesForProducts() {
    const result = await axios.get("/api/categories-for-products");
    return result.data?.response;
  }

  /**
   * approveService
   * @description Approve single service by id
   * @static
   * @param {Number} id - approve service by id
   * @returns {Object.<Service>}
   * @memberOf Service
   */
  async approveService(id) {
    const response = await axios.put("/api/cms/service/approve", {
      id,
    });
    return response.data;
  }

  /**
   * addCar
   * @description Add car
   * @static
   * @param {Object} data - add values
   * @returns {Object.<Car>} -
   * @memberOf Service
   */
  async addCar(data) {
    const response = await axios.post("/api/car", data);
    return response.data;
  }

  /**
   * getMappedCar
   * @description Add car
   * @static
   * @param {String} brand - Car brand
   * @param {String} product - Car product
   * @param {String} model - Car model
   * @param {String} grade - Car grade
   * @returns {Object.<Car>} -
   * @memberOf Service
   */
  // async getMappedCar(brand, product, model, grade, category) {
  //   const response = await axios.get(`/api/mapcars`, {
  //     params: { brand, product, model, grade, category },
  //     progressBar: true
  //   });
  //   return response.data;
  // }

  /**
   * getCars
   * @description  get Car
   * @static
   * @param {String} searchFilter - Search filter
   * @param {String} approvalFilter - for fetching only approved categories
   * @param {String} statusFilter - status filter i-e 'Active' and 'Suspended'
   * @param {Number} pageIndex - Number of records Skip
   * @param {Number} pageSize - Number of records fetch limt
   * @param {Object.<Options>} options - Extra options related to query & object creation
   * @returns {Array.<Car>} - return array of Service operator sorted by date
   * @memberOf Service
   */
  async getCars(
    searchFilter,
    statusFilter,
    categoryFilter,
    _pageIndex,
    pageSize,
    options = {},
    summary
  ) {
    const response = await axios.get("/api/cars", {
      params: {
        searchFilter,
        statusFilter,
        categoryFilter,
        _pageIndex,
        pageSize,
        alphabeticalOrder: options.alphabeticalOrder,
        summary,
      },
      progressBar: options.progressBar,
    });
    return response.data?.response;
  }

  async getTrackFeedbacks(
    searchFilter,
    statusFilter,
    _pageIndex,
    pageSize,
    options = {},
    summary
  ) {
    const result = await axios.get("/api/fast-collect/feedbacks", {
      params: {
        searchFilter,
        statusFilter,
        _pageIndex,
        pageSize,
        alphabeticalOrder: options.alphabeticalOrder,
        summary,
      },
      progressBar: options.progressBar,
    });
    return result?.data?.response;
  }

  async getUpsellRecommendations(
    searchFilter,
    _pageIndex,
    pageSize,
    options = {},
    summary
  ) {
    const result = await axios.get("/api/upsell-recommendations", {
      params: {
        searchFilter,
        _pageIndex,
        pageSize,
        alphabeticalOrder: options.alphabeticalOrder,
        summary: summary,
      },
      progressBar: options.progressBar,
    });
    return result.data?.response;
  }

  async getUpsellRecommendation(id) {
    const result = await axios.get("/api/upsell-recommendation", {
      params: {
        id,
      },
    });
    return result.data?.response;
  }

  async getBranchesAndServices() {
    const result = await axios.get("/api/branches-and-services");
    return result?.data?.response;
  }

  async getscheduleAppointment(
    searchFilter,
    statusFilter,
    expireFilter,
    subscriptionAppointment,
    collectionAndDeliveryAppointment,
    expressAppointment,
    expressApproval,
    branchesFilter,
    servicesFilter,
    pageIndex,
    pageSize,
    options = {}
  ) {
    const result = await axios.get("/api/schedule-appointment", {
      params: {
        searchFilter,
        statusFilter,
        expireFilter,
        subscriptionAppointment,
        collectionAndDeliveryAppointment,
        expressAppointment,
        expressApproval,
        branchesFilter,
        servicesFilter,
        pageIndex,
        pageSize,
        fromDate: options.fromDate,
        toDate: options.toDate,
        summary: options.summary,
        // alphabeticalOrder: options.alphabeticalOrder,
      },
      progressBar: options.progressBar,
    });
    return result?.data?.response;
  }

  async getAllServiceAdvisor(id) {
    const result = await axios.get("/api/service-advisor", {
      params: {
        id,
      },
    });
    return result?.data?.response;
  }

  async assignServiceAdvisor({ id, serviceAdvisor }) {
    const result = await axios.put("/api/service-advisor", {
      id,
      serviceAdvisor,
    });
    return result?.data?.response;
  }

  async getscheduleAppointmentById(id, options = {}) {
    const response = await axios.get("/api/schedule-appointment-id", {
      params: { id },
      progressBar: options.progressBar,
    });
    return response.data;
  }

  async getRequestEstimation(
    searchFilter,
    statusFilter,
    // categoryFilter,
    pageIndex,
    pageSize,
    fromDate,
    toDate,
    options = {},
    summary
  ) {
    const result = await axios.get("/api/estimation-request", {
      params: {
        searchFilter,
        statusFilter,
        // categoryFilter,
        pageIndex,
        pageSize,
        fromDate,
        toDate,
        summary,
        // alphabeticalOrder: options.alphabeticalOrder,
      },
      progressBar: options.progressBar,
    });
    return result.data.response;
  }

  async getScheduleEstimationById(id, options = {}) {
    const result = await axios.get(`/api/estimation-request/${id}`, {
      params: {
        progressBar: options.progressBar,
        appointment: options.appointment,
      },
    });
    return result.data.response;
  }

  /**
   * get car
   * @description get car
   * @static
   * @param {Number} id - get by id
   * @param {Object.<Options>} options - Extra options related to query & object creation
   * @returns {Object.<Car>} -
   * @memberOf Service
   */
  async getCar(id, options = {}) {
    const response = await axios.get(`/api/car/${id}`, {
      progressBar: options.progressBar,
    });
    return response.data;
  }

  /**
   * updateCar
   * @description update car
   * @static
   * @param {Object} values - values
   * @returns {Object.<Car>} -
   * @memberOf Service
   */
  async updateCar(values) {
    const response = await axios.put(`/api/car`, values);
    return response.data;
  }
  /**
   * changePassword
   * @description change Password
   * @static
   * @param {Object} values - values
   * @returns {Object.<User>} -
   * @memberOf Service
   */
  async changePassword(values) {
    const result = await axios.put("/api/auth/change-password", values);
    return result.data;
  }

  /**
   * changeWebPassword
   * @description change Web Password
   * @static
   * @param {Object} values - values
   * @returns {Object.<User>} -
   * @memberOf Service
   */
  async changeWebPassword(values) {
    const result = await axios.put("/api/auth/change-password", values);
    return result.data?.response;
  }

  /**
   * get car
   * @description get car
   * @static
   * @param {Array} ids - get by ids
   * @returns {Array.<Car>} -
   * @memberOf Service
   */
  async carsByIds(ids) {
    console.log("ids", ids);
    const response = await axios.post("/api/multiplecars", { ids });
    return response.data;
  }

  // async carsByIdsWithQty_old(categoryId, ids) {
  //   const response = await axios.get("/api/multiplecarswithqty", {
  //     params: { ids, categoryId }
  //   });
  //   return response.data;
  // }
  async carsByIdsWithQty(categoryId, carCateogory, ids) {
    const response = await axios.post(
      "/api/multiplecarswithqty",
      {
        ids,
        carCateogory,
        categoryId,
      },
      { progressBar: true }
    );
    return response.data;
  }

  async getOrgChart() {
    const response = await axios.get("/api/org/export", {
      params: {
        json: "1",
      },
      progressBar: true,
    });

    return response.data;
  }

  /**
   * getSugestions
   * @description  get Suggestions
   * @static
   * @param {Number} pageIndex - Number of records Skip
   * @param {Number} pageSize - Number of records fetch limt
   * @param {String} field - Suggest field name i-e "English name", "Arabic name" and "name"
   * @param {Number} value - Value for building sugestions
   * @param {Number} type - type of screen
   * @returns {Array.<Suggestions>} - return array of Service operator sorted by date
   * @memberOf Service
   */

  async getSugestions(pageIndex, pageSize, field, value, type) {
    const response = await axios.get("/api/suggestion/core", {
      params: {
        pageIndex,
        pageSize,
        field,
        type,
        value,
      },
      progressBar: true,
    });
    return response.data;
  }

  async getTopPerformer(from, to) {
    const response = await axios.get("/api/dashboard/performers", {
      params: {
        from,
        to,
      },
      progressBar: true,
    });
    return response.data;
  }

  async getDownloads(from, to) {
    const response = await axios.get("/api/dashboard/downloads", {
      params: {
        from,
        to,
      },
      progressBar: true,
    });
    return response.data;
  }

  async getPopularCities(from, to) {
    const response = await axios.get("/api/dashboard/popular/cities", {
      params: {
        from,
        to,
      },
      progressBar: true,
    });
    return response.data;
  }

  async getUserActivity(from, to) {
    const response = await axios.get("/api/dashboard/activity", {
      params: {
        from,
        to,
      },
      progressBar: true,
    });
    return response.data;
  }

  async getCacheCar(id) {
    console.log("get comment fn hit", id);
    const response = await axios.get("/api/cars/cache", { progressBar: true });
    return response.data;
  }

  // async getMappedCar(brand, product, model, grade, category) {
  //   const response = await axios.post(`/api/map/cars`, {
  //     brand,
  //     product,
  //     model,
  //     grade,
  //     category,
  //   });
  //   return response.data;
  // }

  getMimeTypeFromExtension(filename) {
    const extension = filename.split(".").pop().toLowerCase();
    switch (extension) {
      case "jpg":
        return ".jpg";
      case "jpeg":
        return ".jpeg";
      case "png":
        return ".png";
      case "image/svg+xml":
        return ".svg";
      case "image/webp":
        return ".webp";
      case "pdf":
        return ".pdf";
      default:
        return ".png";
    }
  }

  async downloadFile(key, type) {
    if (key === undefined) return;

    let originalKey = key;
    // key = key.split("_")[0];
    let filename = originalKey;
    filename = key.slice(37, key.length);
    const response = await axios.get("/api/util/download", {
      params: { key },
      headers: { imageKey: "xzic2b7Fei1GJlKuQsJcBtIo12qfnJ5W" },
      progressBar: true,
      responseType: "blob",
    });
    let data = [];

    data = response.config.params.key.split("75973");
    if (_.isEmpty(data)) {
      data = response.config.params.key.split("43770");
    }
    filename =
      filename != undefined && filename != null && filename != ""
        ? filename
        : data[1];

    if (type === "view") {
      return blobToFile(response.data, filename, "application/pdf");
    } else if (type === "blob") {
      const extension = this.getMimeTypeFromExtension(filename);
      return { extension, blob: response.data };
    }

    FileDownload(
      response.data,
      filename,
      "application/pdf image/jpeg image/jpg image/png"
    );
  }

  async getTranslations() {
    const response = await axios.get("/api/translation/messages");
    return response.data;
  }

  async updateTranslation(translation) {
    const response = await axios.post("/api/translation", translation);
    return response.data;
  }

  async updateTranslations(translation) {
    console.log("updateTranslation", translation);
    const response = await axios.post("/api/data/translations", {
      translation: translation,
    });
    return response.data;
  }

  async deleteTranslation(id) {
    const response = await axios.put("/api/translations", { id: id });
    return response.data;
  }

  async updateAppTranslation(app, translation) {
    const result = await axios.post(`/api/${app}/translation`, translation);
    return result?.data?.response;
  }

  async updateAppTranslations(app, translation) {
    const response = await axios.post(`/api/data/${app}/translations`, {
      translation: translation,
    });
    return response.data;
  }

  async deleteAppTranslation(app, id) {
    const response = await axios.put(`/api/${app}/translations`, { id });
    return response.data;
  }

  async addRole(roleData) {
    const response = await axios.post("/api/role", roleData);
    return response.data;
  }

  async updateRole(roleUpdateData) {
    const response = await axios.put("/api/role", roleUpdateData);
    return response.data;
  }

  async getRole(id) {
    const response = await axios.get("/api/role", { params: { id } });
    return response.data;
  }

  async getRoles(searchFilter, pageIndex, pageSize, options = {}) {
    const response = await axios.get("/api/roles", {
      params: {
        searchFilter,
        pageIndex,
        pageSize,
      },
      progressBar: options.progressBar,
    });
    return response.data;
  }

  async getBrands(searchFilter, options = {}) {
    const response = await axios.get("/api/brands", {
      params: {
        searchFilter,
      },
      progressBar: options.progressBar,
    });
    return response.data;
  }

  async updateBrandLogo(data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.post("/api/brand", formData);
    return response.data;
  }

  async deleteRole(id) {
    const response = await axios.delete("/api/role", {
      params: { id },
    });
    return response.data;
  }

  async addAdmin(adminData) {
    const result = await axios.post("/api/admin", adminData);
    return result.data?.response;
  }

  async updateAdmin(adminUpdateData) {
    const result = await axios.put("/api/admin", adminUpdateData);
    return result.data?.response;
  }

  async getAdmin(id) {
    const result = await axios.get("/api/admin", { params: { id } });
    return result.data?.response;
  }
  async profile() {
    const response = await axios.get("/api/profile/header");
    return response.data;
  }
  async getAdmins(
    searchFilter,
    statusFilter,
    rolesFilter,
    pageIndex,
    pageSize,
    options = {}
  ) {
    const result = await axios.get("/api/admins", {
      params: {
        searchFilter,
        statusFilter,
        rolesFilter: rolesFilter.join(","),
        pageIndex,
        pageSize,
        summary: options?.summary,
      },
    });
    return result.data?.response;
  }

  async changeLanguage(language) {
    const response = await axios.post("/api/language", { language });
    return response.data;
  }

  async getShopCities() {
    const response = await axios.get("/api/shop/cities");
    return response.data;
  }

  async addComments(data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.put("/api/estimation/comment", formData);
    return response.data;
  }

  async cancelRequest(payload) {
    const response = await axios.put("/api/estimation/cancel-request", payload);
    return response.data;
  }

  async addExpressComment(comment, id) {
    const response = await axios.put("/api/express-appointment/comment", {
      comment,
      id,
    });
    return response.data;
  }

  async saveToken(token, options = {}) {
    const response = await axios.post("/api/token", {
      token,
    });
    return response.data;
  }

  async deleteToken(token, options = {}) {
    const response = await axios.delete("/api/token", {
      params: { token },
    });
    return response.data;
  }

  canPerformActivity(activityId) {
    if (!appState.state.token || !activityId) return false;

    if (!appState.state.token?.isSubAdmin) return true;

    return appState.state.token?.activities.indexOf(activityId) >= 0;
  }

  hasAccessToModule(moduleId) {
    if (!appState.state.token || !moduleId) return false;

    if (!appState.state.token?.isSubAdmin) return true;

    return appState.state.token?.modules.indexOf(moduleId) >= 0;
  }

  stickyGridHeader(
    overflowElement = ".dashboard-main",
    horizontalFeature = false
  ) {
    if ($(".dashboard-table-container").length > 0) {
      const elem = $(".dashboard-table-container thead");
      const topOffset = elem.offset().top;

      $(overflowElement).scroll(function () {
        const currentPos = $(overflowElement).scrollTop();

        if (currentPos >= topOffset) {
          $("table").floatThead({ top: 80 });
        } else {
          $("table").floatThead("destroy");
        }
      });

      if (horizontalFeature) {
        let scrolledLeftPrevious = 0;
        $(".table-responsive").scroll(function () {
          const scrolledLeft = $(this).scrollLeft();
          if (scrolledLeft > scrolledLeftPrevious) {
            $(".floatThead-container").css("left", -scrolledLeft);
          } else {
            $(".floatThead-container").css("left", scrolledLeft);
          }
          scrolledLeftPrevious = scrolledLeft;
        });
      }
    }
  }

  mobileAndTabletcheck() {
    var check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || window.opera);
    // console.log("Mobile Device", check);
    return check;
  }

  async getVersion() {
    const response = await axios.get("/api/settings");
    return response.data;
  }

  async getUserList(
    searchFilter,
    statusFilter,
    categoryFilter,
    pageIndex,
    pageSize,
    options = {}
  ) {
    const response = await axios.get("/api/user-list", {
      params: {
        searchFilter,
        statusFilter,
        categoryFilter,
        pageIndex,
        pageSize,
        fromDate: options.fromDate,
        toDate: options.toDate,
        summary: options.summary,
      },
      progressBar: options.progressBar,
    });
    return response.data;
  }
  async restrictUserAccount(payload) {
    const response = await axios.put("/api/restrict-account", payload);
    return response?.data;
  }

  async addFooterImages(data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.post("/api/footer", formData);
    return response.data.response;
  }

  async getFooterData(options = {}) {
    const response = await axios.get("/api/footer", {
      progressBar: options.progressBar,
    });
    return response.data.response;
  }

  async getLocationList(
    searchFilter,
    statusFilter,
    categoryFilter,
    pageIndex,
    pageSize,
    options = {}
  ) {
    const response = await axios.get("/api/location-list", {
      params: {
        searchFilter,
        statusFilter,
        categoryFilter,
        pageIndex,
        pageSize,
      },
      progressBar: options.progressBar,
    });
    return response.data;
  }

  async addLocationData(
    countryNameEn,
    countryNameAr,
    cityNameEn,
    cityNameAr,
    titleEn,
    titleAr,
    addressEn,
    addressAr,
    isActive
  ) {
    const request = {
      countryNameEn: countryNameEn,
      countryNameAr: countryNameAr,
      cityNameEn: cityNameEn,
      cityNameAr: cityNameAr,
      titleEn: titleEn,
      titleAr: titleAr,
      addressEn: addressEn,
      addressAr: addressAr,
      isActive: isActive,
    };
    const response = await axios.post("/api/add-location", request);
    return response.data;
  }

  async getAppLocalization(
    app,
    searchFilter,
    pageIndex,
    pageSize,
    options = {},
    xlsx = false,
    summary
  ) {
    const result = await axios.get(`/api/${app}/localization`, {
      params: {
        searchFilter,
        pageIndex,
        pageSize,
        xlsx,
        summary,
      },
      responseType: xlsx ? "blob" : undefined,
      progressBar: options.progressBar,
    });

    if (xlsx) {
      FileDownload(
        result.data,
        `TheLoyaltyApp_${
          app ? app + "_translation" : app + "_" + "translation"
        }_${moment().format("DDMMYYYY")}.xlsx`,
        "application/vnd.openxmlformats"
      );
    }

    return result?.data?.response;
  }

  async updateLocationData(
    id,
    countryNameEn,
    countryNameAr,
    cityNameEn,
    cityNameAr,
    titleEn,
    titleAr,
    addressEn,
    addressAr,
    isActive
  ) {
    const request = {
      id: id,
      countryNameEn: countryNameEn,
      countryNameAr: countryNameAr,
      cityNameEn: cityNameEn,
      cityNameAr: cityNameAr,
      titleEn: titleEn,
      titleAr: titleAr,
      addressEn: addressEn,
      addressAr: addressAr,
      isActive: isActive,
    };
    const response = await axios.put("/api/update-location", request);
    return response.data;
  }

  async getLocationtById(id, options = {}) {
    const response = await axios.get("/api/location-id", {
      params: { id },
      progressBar: options.progressBar,
    });
    return response.data;
  }

  async getServicesList(branchId) {
    let key = branchId ? `?branchId=${branchId}` : "";
    const result = await axios.get("/api/service-list" + key);
    return result?.data?.response;
  }

  async saveNotificationsContent(data) {
    const result = await axios.post("/api/user-notification/content", data);
    return result.data;
  }
  async saveNotificationsEmailContent(data) {
    const result = await axios.post("/api/user-notification/emails", data);
    return result.data;
  }

  async getNotificationsContent() {
    const result = await axios.get("/api/user-notification/content");
    return result.data.response;
  }
  async getNotificationsEmailContent() {
    const result = await axios.get("/api/user-notification/emails");
    return result.data.response;
  }
  async getKaizens(
    searchFilter,
    approvalFilter,
    statusFilter,
    pageIndex,
    pageSize,
    alphabeticalOrder,
    options = {}
  ) {
    const response = await axios.get("/api/kaizens-cms", {
      params: {
        searchFilter,
        approvalFilter,
        statusFilter,
        pageIndex,
        pageSize,
        alphabeticalOrder,
        summary: options.summary,
      },
      progressBar: options.progressBar,
    });
    return response.data;
  }
  async getKaizenById(id, options = {}) {
    const response = await axios.get("/api/kaizen", {
      params: { id },
      progressBar: options.progressBar,
    });
    return response.data;
  }
  async addKaizen(data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.post("/api/kaizen", formData);
    return response.data;
  }
  async updateKaizen(data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const response = await axios.put("/api/kaizen", formData);
    return response.data;
  }

  /**
   * addCategory
   * @description add category
   * @static
   * @param {Object} data - add values
   * @returns {Object.<Category>} -
   * @memberOf Service
   */
  async addCategory(data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const result = await axios.post("/api/category", formData);
    return result.data?.response;
  }

  /**
   * getCategory
   * @description get single category
   * @static
   * @param {Number} id - get category by number
   * @param {Object.<Options>} options - Extra options related to query & object creation
   * @returns {Object.<Category>} -
   * @memberOf Service
   */
  async getCategory(id, options = {}) {
    const result = await axios.get("/api/category", {
      params: { id },
      progressBar: options.progressBar,
    });
    return result.data?.response;
  }

  /**
   * updateCategory
   * @description update single category
   * @static
   * @returns {Object.<Category>} -
   * @memberOf Service
   */
  async updateCategory(data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const result = await axios.put("/api/category", formData);
    return result.data?.response;
  }

  /**
   * getCategories
   * @description get categories
   * @static
   * @param {String} searchFilter - Search filter
   * @param {String} statusFilter - status filter i-e 'Active' and 'Suspended'
   * @param {Number} pageIndex - Number of records Skip
   * @param {Number} pageSize - Number of records fetch limt
   * @param {String} alphabeticalOrder - get category by number
   * @param {Object.<Options>} options - Extra options related to query & object creation
   * @returns {Array.<Categories>} - return array of Categories sorted by date
   * @memberOf Service
   */
  async getCategories(
    searchFilter,
    statusFilter,
    pageIndex,
    pageSize,
    alphabeticalOrder,
    options = {},
    isServiceCategory = false
  ) {
    const result = await axios.get("/api/categories", {
      params: {
        searchFilter,
        statusFilter,
        pageIndex,
        pageSize,
        alphabeticalOrder,
        summary: options.summary,
        fromDate: options.fromDate,
        toDate: options.toDate,
        categories: options.categories,
        isServiceCategory,
      },
      progressBar: options.progressBar,
    });
    return result.data?.response;
  }

  async getCustomersForPromos(searchFilter, options = {}) {
    const response = await axios.get("/api/promos/customers", {
      params: {
        searchFilter,
      },
      progressBar: options.progressBar,
    });
    return response.data;
  }

  async getAllPromos() {
    const response = await axios.get("/api/allPromos", {});
    return response.data;
  }

  async getPromos(
    searchFilter,
    statusFilter,
    pageIndex,
    pageSize,
    options = {}
  ) {
    const response = await axios.get("/api/promos", {
      params: {
        searchFilter,
        statusFilter,
        pageIndex,
        pageSize,
        summary: options?.summary,
      },
      progressBar: options.progressBar,
    });
    return response.data;
  }

  async getPromo(id, options = {}) {
    const response = await axios.get(`/api/promo/${id}`, {
      progressBar: options.progressBar,
    });
    return response.data;
  }

  async addPromo(data) {
    const response = await axios.post("/api/promo", data);
    return response.data;
  }

  async addMultiplePromo(data) {
    const response = await axios.post("/api/promo/multiple/codes", data);
    return response.data;
  }

  async updatePromo(values) {
    const response = await axios.put(`/api/promo`, values);
    return response.data;
  }

  async getOffersForPromo() {
    const response = await axios.get("/api/offers-for-promo");
    return response.data?.response;
  }

  async getAllBranches() {
    const result = await axios.get("/api/all-branch-ids");
    return result.data?.response;
  }

  async getAllProducts() {
    const result = await axios.get("/api/all-product-ids");
    return result.data?.response;
  }

  async getBranchesForPromo() {
    const result = await axios.get("/api/branches-for-promo");
    return result.data?.response;
  }
  async downloadCustomerList(
    searchFilter,
    statusFilter,
    fromDate,
    toDate,
    options = {}
  ) {
    const xlsx = true;
    const response = await axios.get("/api/user-list", {
      params: {
        searchFilter,
        statusFilter,
        fromDate,
        toDate,
        xlsx: xlsx,
      },
      responseType: "blob",
    });
    console.log("resp", response.data);
    FileDownload(
      response.data,
      `autohub_customer_${moment().format("DDMMYYYY")}.xlsx`,
      "application/vnd.openxmlformats"
    );
  }
  async downloadAppointmentList(
    searchFilter,
    statusFilter,
    fromDate,
    toDate,
    isAppointmentDateFilter,
    options = {}
  ) {
    const xlsx = true;
    const response = await axios.get("/api/schedule-appointment", {
      params: {
        searchFilter,
        statusFilter,
        fromDate,
        toDate,
        xlsx: xlsx,
        isAppointmentDateFilter,
      },
      responseType: "blob",
    });
    console.log("resp", response.data);
    FileDownload(
      response.data,
      `autohub_appointments_${moment().format("DDMMYYYY")}.xlsx`,
      "application/vnd.openxmlformats"
    );
  }
  async downloadEstimationReport(
    searchFilter,
    statusFilter,
    fromDate,
    toDate,
    options = {}
  ) {
    const xlsx = true;
    const response = await axios.get("/api/estimation-request", {
      params: {
        searchFilter,
        statusFilter,
        fromDate,
        toDate,
        xlsx: xlsx,
      },
      responseType: "blob",
    });
    FileDownload(
      response.data,
      `autohub_estimations_${moment().format("DDMMYYYY")}.xlsx`,
      "application/vnd.openxmlformats"
    );
  }
  async downloadOrderReport(
    searchFilter,
    statusFilter,
    fromDate,
    toDate,
    options = {}
  ) {
    const xlsx = true;
    const response = await axios.get("/api/orders", {
      params: {
        searchFilter,
        statusFilter,
        fromDate,
        toDate,
        xlsx: xlsx,
      },
      responseType: "blob",
    });
    console.log("resp", response.data);
    FileDownload(
      response.data,
      `autohub_orders_${moment().format("DDMMYYYY")}.xlsx`,
      "application/vnd.openxmlformats"
    );
  }

  async serviceAdvisorLogin(values) {
    const result = await axios.post("/api/auth/service-advisor/login", values);
    return result && result.data;
  }

  async serviceAdvisorVerification(code) {
    const result = await axios.post("/api/auth/service-advisor/verify", code);
    return result && result.data?.response;
  }

  async getProgress(phone) {
    const result = await axios.get("/api/track", {
      params: {
        phone: phone,
        isFromCms: true,
      },
    });
    return result.data?.response;
  }

  async getCndTrack({ phoneNumber, source }) {
    const result = await axios.get("/api/cnd-track", {
      params: {
        phoneNumber,
        isFromCms: true,
        source,
      },
    });
    return result.data?.response;
  }

  async getServicesV3(servicesID, action) {
    const result = await axios.get("/api/services/car-own-by", {
      params: {
        servicesID,
        action,
      },
    });
    return result?.data?.response;
  }

  async saveCarOwnBy(docValues, service) {
    const response = await axios.put("/api/car-own-by", {
      docValues,
      service,
    });
    return response && response.data;
  }

  async getCarOwnBy() {
    const result = await axios.get("/api/car-own-by");
    return result?.data?.response;
  }

  async getServiceCarOwnBy(id) {
    const response = await axios.get("/api/car-own-by/service", {
      params: {
        id,
      },
    });
    return response && response.data;
  }

  async saveBookingAppointmentFor(docValues, service) {
    const result = await axios.put("/api/booking-appointment-for", {
      docValues,
      service,
    });
    return result && result.data?.response;
  }

  async getBookingAppointmentFor() {
    const result = await axios.get("/api/booking-appointment-for");
    return result && result.data?.response;
  }
  async getServiceBookingAppointmentFor(id) {
    const result = await axios.get("/api/service/booking-appointment-for", {
      params: {
        id,
      },
    });
    return result && result.data?.response;
  }
  async getRequiredDocuments() {
    const result = await axios.get("/api/required-documents");
    return result && result.data?.response;
  }
  async getServiceRequiredDocuments(id) {
    const result = await axios.get("/api/service/required-documents", {
      params: {
        id,
      },
    });
    return result && result.data?.response;
  }
  async saveRequiredDocuments(docValues, service) {
    const result = await axios.put("/api/required-documents", {
      docValues,
      service,
    });
    return result && result.data?.response;
  }

  async getServicesAgainstCarOwnBy(summary) {
    const response = await axios.get("/api/car-own-by/services", {
      params: {
        summary: summary,
      },
    });
    return response && response.data;
  }
  async getQuoteServicesAgainstCarOwnBy(servicesID) {
    const response = await axios.get("/api/car-own-by/quote-services", {
      params: {
        servicesID,
      },
    });
    return response && response.data;
  }

  async cancelAppointment(data) {
    const response = await axios.post("/api/cancel-appointment", data);
    return response && response.data;
  }

  async sendFastCollectInfo(data) {
    const formData = new FormData();
    for (const key in data) {
      formData.append(key, data[key]);
    }

    const result = await axios.post("/api/fast-collect", formData);
    return result?.data?.response;
  }

  async getFastCollectInfo(filters) {
    const result = await axios.get("/api/fast-collect", { params: filters });
    return result?.data?.response;
  }

  async addRecommendation(data) {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const result = await axios.post("/api/recommendation", formData);
    return result.data?.response;
  }

  async getRecommendedOffers({ phoneNumber, carPlateNumber, appointmentId }) {
    const result = await axios.get("/api/recommendation-history", {
      params: {
        phoneNumber,
        appointmentId,
        carPlateNumber,
      },
    });
    return result.data?.response;
  }

  async getFastCollectFeedback(id) {
    const result = await axios.get("/api/fast-collect/feedback", {
      params: {
        id,
      },
    });
    return result?.data?.response;
  }

  async assignServiceAdvisorTrack(payload) {
    const result = await axios.put(
      "/api/fast-collect/assign-service-advisor",
      payload
    );

    return result?.data?.response;
  }

  async updateRecommendedOffer(payload) {
    const result = await axios.put(
      "/api/recommended-offer/active-status",
      payload
    );

    return result.data;
  }

  async updateCarProgress(payload) {
    const result = await axios.put("/api/fast-collect/active-status", payload);

    return result?.data?.response;
  }

  async downloadDepositInvoice(id, type) {
    const result = await axios.get(`/api/order/${id}/invoice/${type}`, {
      responseType: "blob",
    });

    return result?.data;
  }

  async downloadZatcaInvoice(uuid) {
    const result = await axios.get(`/api/zatca-invoice-pdf?uuid=${uuid}`, {
      responseType: "arraybuffer",
    });

    return result?.data;
  }

  async resendInvoiceToZatca({ orderId, orderType, isDeposit, isRefund }) {
    const result = await axios.put(`/api/order/resend-invoice`, {
      orderId,
      orderType,
      isDeposit,
      isRefund,
    });

    return result?.data?.response;
  }
  async resendInvoiceToOracle({ id, orderType, isDeposit }) {
    const result = await axios.put(`/api/order/resend/oracle-invoice`, {
      id,
      orderType,
      isDeposit,
    });

    return result?.data?.response;
  }

  async downloadFinalInvoice(id, type) {
    const result = await axios.get(`/api/delivery/${id}/invoice/${type}`, {
      responseType: "blob",
    });

    return result?.data;
  }

  async getActivities(
    searchFilter,
    areaFilter,
    actionFilter,
    idFilter,
    pageIndex,
    pageSize,
    options = {},
    summary
  ) {
    const result = await axios.get("/api/activity", {
      params: {
        searchFilter,
        areaFilter,
        actionFilter,
        idFilter,
        fromDate: options?.fromDate,
        toDate: options?.toDate,
        pageIndex,
        pageSize,
        xlsExport: options.xlsExport,
        summary,
      },
      progressBar: options.progressBar,
      responseType: options.xlsExport ? "blob" : undefined,
    });

    // if (options.xlsExport)
    //   FileDownload(
    //     result.data,
    //     `CarHub_UserActivities_${moment().format("DDMMYYYY")}.xlsx`,
    //     "application/vnd.openxmlformats"
    //   );
    return result.data?.response;
  }
  async getActivityFilters() {
    const result = await axios.get("/api/activity/filters");
    return result.data?.response;
  }
  async createOrder(orderPayload) {
    const result = await axios.post(`/api/order`, orderPayload);

    return result?.data?.response;
  }
  async sendOtpToAdmin() {
    const result = await axios.put(`/api/admin/otp`);

    return result?.data;
  }
  async verifyAdminOtp(code) {
    const result = await axios.put(`/api/admin/verify-otp`, { code });

    return result?.data;
  }

  // ALERTS API
  async getAlertsNotificationsByAdminId() {
    const result = await axios.get("/api/alerts");
    return result.data?.response;
  }
  async getAlertsUnseenCount() {
    const result = await axios.get("/api/alerts-count");
    return result.data?.response;
  }
  async updateAlertsSeen() {
    const result = await axios.put("/api/alerts-seen");
    return result.data?.response;
  }
}

export default new Services();
