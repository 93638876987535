import BaseService from "./baseService";
import axios from "axios";

class BranchService extends BaseService {
  constructor() {
    super();
  }

  async addBranch({ active, ...restData }) {
    const request = {
      ...restData,
      active: active ? "Yes" : "No",
    };
    const response = await axios.post("/api/branch", request);
    return response.data;
  }

  async updateBranch(data, id) {
    const request = {
      ...data,
      active: data.active ? "Yes" : "No",
    };
    const response = await axios.put("/api/branch/" + id, request);
    return response.data;
  }

  async deleteBranch(id) {
    const response = await axios.delete("/api/branches/" + id);
    return response.data;
  }

  async getActiveBranches() {
    const result = await axios.get("/api/dashboard-branches");
    return result?.data?.response;
  }
  async getBranches(
    searchFilter,
    statusFilter,
    categoryFilter,
    pageIndex,
    pageSize,
    options = {}
  ) {
    const result = await axios.get("/api/branches", {
      params: {
        searchFilter,
        statusFilter: statusFilter ? statusFilter : "all",
        categoryFilter,
        pageIndex,
        pageSize,
        summary: options?.summary,
        // alphabeticalOrder: options.alphabeticalOrder,
      },
      progressBar: options.progressBar,
    });
    return result.data?.response;
  }

  async getBranch(id) {
    const options = {};
    if (id) options.params = { id };
    const result = await axios.get("/api/branch", {
      params: { id },
    });
    return result.data?.response;
  }

  async getSlotsByDay(branchId, serviceId, day) {
    const result = await axios.get(
      `/api/branches/get-slots?branchId=${branchId}&serviceId=${serviceId}&dayFilter=${day}`
    );
    return result.data.response;
  }

  async getSlotsByService(branchId, serviceIds) {
    const result = await axios.get("/api/branches/get-slots", {
      params: {
        branchId,
        serviceId: serviceIds,
      },
    });
    return result.data.response;
  }

  async getOcassions(id, startDate, endDate) {
    const options = {};
    if (startDate && endDate) options.params = { startDate, endDate };
    const result = await axios.get("/api/branches/ocassions/" + id, options);
    return result.data.response;
  }

  async getOffersAgainstService(servicesId) {
    const result = await axios.get("/api/offers-against-service");
    return result?.data?.response;
  }

  async getBranchesName() {
    const result = await axios.get("/api/branches-name");
    return result?.data?.response;
  }
}

export default new BranchService();
